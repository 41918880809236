// Chakra imports
import { Flex } from '@chakra-ui/react';

import { HSeparator } from 'components/separator/Separator';

export function SidebarBrand() {

	return (
		<Flex alignItems='center' flexDirection='column'>
			<h1 style={{ fontSize: '2.5rem', marginBottom: '1rem', fontWeight: 'bold' }}>A4A</h1>
			<HSeparator mb='20px' />
		</Flex>
	);
}

export default SidebarBrand;
