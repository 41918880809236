import { ADD_MUSEUM, DELETE_MUSEUM, EDIT_MUSEUM, GET_ALL_MUSEUMS, RESET_MUSEUM } from 'store/Actions/types'
import reduxActionType from '../../variables/reduxActionType'

const initialState = {
  data: [] as []
}

function MuseumReducer (state = initialState, action: reduxActionType) {
  switch (action.type) {
    case GET_ALL_MUSEUMS: {
      return {
        ...state,
        data: action.payload
      }
    }
    case ADD_MUSEUM: {
      return {
        ...state,
        data: [action.payload, ...state.data]
      }
    }
    case EDIT_MUSEUM: {
      return {
        ...state,
        data: state.data.map(
          (museum: any, i) => museum.id === action.payload.id ? { ...museum, ...action.payload.data }
              : museum
        )
      }
    }
    case DELETE_MUSEUM: {
      return {
        ...state,
        data: state.data.filter((museum: any) => museum.id !== action.payload)
      }
    }
    case RESET_MUSEUM: {
      return {
        ...state,
        data: []
      }
    }
    default:
      return state
  }
}

export default MuseumReducer
