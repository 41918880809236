// Chakra imports
import { Flex, useToast } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getBeaconsList } from 'store/Actions/BeaconActions';
import { getMuseumsList } from 'store/Actions/MuseumActions';
import ExhibitForm from '../form/index';
import Notification from 'components/notification/Notification';
import { RootContext } from 'contextApi';
import { deleteExhibit, getExhibitList } from 'store/Actions/ExhibitActions';
import ExhibitsTable from './components/ExhibitsTable';
import { exhibitsColumns } from './variables/exhibitColumns';

// @ts-ignore
const ExhibitList = ({ getBeaconsList, getExhibitList, exhibits, deleteExhibit, getMuseumsList, museums }) => {

	const [modal, setModal] = useState(false);
	const [exhibit, setExhibit] = useState({});
	const [loading, setLoading] = useState(false);
	const [loadingData, setLoadingData] = useState(false);
	const [notification, setNotification] = useState({ isVisible: false, heading: "", text: "" });
	const { museum } = useContext(RootContext);

	useEffect(() => {
		setLoadingData(true);
		Promise.all([ getExhibitList(museum) , museums?.data.length === 0 && getMuseumsList()]).then(res => {
			setLoadingData(false);
		}).finally(() => {
			setLoadingData(false);
		});
	}, [getExhibitList, museums?.data.length, getMuseumsList, museum]);

	const data:any = [];
	if(museums?.data.length > 0 && exhibits.data.length > 0) {
		exhibits?.data.forEach((item: any) => {
			const tempItem = JSON.parse(JSON.stringify(item));
			tempItem.actions = item.id;
			data.push(tempItem);
		})
	}

	const editExhibitHandler = (id: number) => {
		setExhibit(exhibits.data.filter((item: any) => item.id === id)[0]);
	}

	const deleteExhibitHandler = (id: number) => {
		try {
			const exhibit = exhibits.data.filter((item: any) => item.id === id)[0]
			setExhibit(exhibit);
			setLoading(true);
			deleteExhibit(id).then((res: any) => {
				getBeaconsList();
				setLoading(false);
				setNotification({
					isVisible: true,
					heading: 'Success',
					text: `${exhibit.exhibitName} has been deleted successfully`
				});
				setTimeout(() => {
					setNotification({isVisible: false, heading: '', text: ''})
				}, 10000);
			});
		} catch(err) {
			setLoading(false);
		}
	}

	return (
		<Flex direction='column' pt={{ sm: '125px', lg: '75px' }}>
			<Card px='0px'>
				<Notification 
					isVisible={notification.isVisible}
					heading={notification.heading}
					text={notification.text}
				/>
				<ExhibitsTable 
					tableData={data} 
					columnsData={exhibitsColumns} 
					onOpen={() => setModal(true)}
					setExhibit={editExhibitHandler}
					deleteExhibitHandler={deleteExhibitHandler}
					loading={loading}
					exhibit={exhibit}
					loadingData={loadingData}
				/>
			</Card>
			<ExhibitForm 
				modal={modal} 
				onClose={() => setModal(false)} exhibit={exhibit} setExhibit={setExhibit} 
				loading={loading}
				setLoading={setLoading}
				setNotification={setNotification}
				exhibits={exhibits}
				museums={museums}
			/>
		</Flex>
	);
}

const mapStateToProps = (state: any) => ({
  exhibits: state.exhibits,
	museums: state.museums,
})

const mapDispatchToProps = {
  getExhibitList,
	deleteExhibit,
	getMuseumsList,
  getBeaconsList
}

export default connect(mapStateToProps, mapDispatchToProps)(ExhibitList);