export const assistanceColumns = [
  {
    Header: "Type",
    accessor: "type",
  },
  {
    Header: "Media",
    accessor: "mediaUrl",
  },
  {
    Header: "Actions",
    accessor: "actions",
  },
];
