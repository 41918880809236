import { ADD_EXHIBIT, DELETE_EXHIBIT, EDIT_EXHIBIT, GET_ALL_EXHIBIT, RESET_EXHIBIT } from 'store/Actions/types'
import reduxActionType from '../../variables/reduxActionType'

const initialState = {
  data: [] as []
}

function ExhibitReducer (state = initialState, action: reduxActionType) {
  switch (action.type) {
    case GET_ALL_EXHIBIT: {
      return {
        ...state,
        data: action.payload
      }
    }
    case ADD_EXHIBIT: {
      return {
        ...state,
        data: [action.payload, ...state.data]
      }
    }
    case EDIT_EXHIBIT: {
      return {
        ...state,
        data: state.data.map(
          (exhibit: any, i) => exhibit.id === action.payload.id ? { ...exhibit, ...action.payload.data }
              : exhibit
        )
      }
    }
    case DELETE_EXHIBIT: {
      return {
        ...state,
        data: state.data.filter((exhibit: any) => exhibit.id !== action.payload)
      }
    }
    case RESET_EXHIBIT: {
      return {
        ...state,
        data: []
      }
    }
    default:
      return state
  }
}

export default ExhibitReducer;
