import { ADD_SUPPORTING_MEDIA, DELETE_SUPPORTING_MEDIA, EDIT_SUPPORTING_MEDIA, GET_ALL_SUPPORTING_MEDIAS, RESET_SUPPORTING_MEDIA } from 'store/Actions/types'
import reduxActionType from '../../variables/reduxActionType'

const initialState = {
  data: [] as []
}

function SupportingMediaReducer (state = initialState, action: reduxActionType) {
  switch (action.type) {
    case GET_ALL_SUPPORTING_MEDIAS: {
      return {
        ...state,
        data: action.payload
      }
    }
    case EDIT_SUPPORTING_MEDIA: {
      return {
        ...state,
        data: state.data.map(
          (supMedia: any, i) => supMedia.id === action.payload.assistanceId ? { ...supMedia, ...action.payload.data }
              : supMedia
        )
      }
    }
    case RESET_SUPPORTING_MEDIA: {
      return {
        ...state,
        data: []
      }
    }
    default:
      return state
  }
}

export default SupportingMediaReducer;
