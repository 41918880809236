// MUSEUMS ACTIONS
export const GET_ALL_MUSEUMS = 'GET_ALL_MUSEUMS'
export const ADD_MUSEUM = 'ADD_MUSEUM'
export const EDIT_MUSEUM = 'EDIT_MUSEUM'
export const DELETE_MUSEUM = 'DELETE_MUSEUM'
export const RESET_MUSEUM = 'RESET_MUSEUM'

// MUSEUMS ACTIONS
export const GET_ALL_EXHIBIT = 'GET_ALL_EXHIBIT'
export const ADD_EXHIBIT = 'ADD_EXHIBIT'
export const EDIT_EXHIBIT = 'EDIT_EXHIBIT'
export const DELETE_EXHIBIT = 'DELETE_EXHIBIT'
export const RESET_EXHIBIT = 'RESET_EXHIBIT'

// BEACONS ACTIONS
export const GET_ALL_BEACONS = 'GET_ALL_BEACONS'
export const ADD_BEACON = 'ADD_BEACON'
export const EDIT_BEACON = 'EDIT_BEACON'
export const DELETE_BEACON = 'DELETE_BEACON'
export const RESET_BEACON = 'RESET_BEACON'

// LOOKUPS ACTIONS
export const GET_ALL_LOOKUPS = 'GET_ALL_LOOKUPS'

// ASSISTANCES ACTIONS
export const GET_ALL_ASSISTANCES = 'GET_ALL_ASSISTANCES'
export const ADD_ASSISTANCE = 'ADD_ASSISTANCE'
export const EDIT_ASSISTANCE = 'EDIT_ASSISTANCE'
export const DELETE_ASSISTANCE = 'DELETE_ASSISTANCE'
export const RESET_ASSISTANCE = 'RESET_ASSISTANCE'

// SUPPORTING_MEDIAS ACTIONS
export const GET_ALL_SUPPORTING_MEDIAS = 'GET_ALL_SUPPORTING_MEDIAS'
export const ADD_SUPPORTING_MEDIA = 'ADD_SUPPORTING_MEDIA'
export const EDIT_SUPPORTING_MEDIA = 'EDIT_SUPPORTING_MEDIA'
export const DELETE_SUPPORTING_MEDIA = 'DELETE_SUPPORTING_MEDIA'
export const RESET_SUPPORTING_MEDIA = 'RESET_SUPPORTING_MEDIA'