import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, useColorModeValue, FormLabel, Select } from '@chakra-ui/react';
import InputField from 'components/fields/InputField';
import { SupportingMediaFormPropTypes } from 'types/propTypes';
import { useFormik } from 'formik';
import { useState } from 'react';
import http from 'services';
import axios from 'axios';
import { connect } from 'react-redux';
import { getFileNameFromUrl, isVideo } from 'helpers/functions';
import { addSupportingMedia, updateSupportingMedia } from 'store/Actions/SupportingMediaActions';
import AssistanceMediaValidation from 'validations/supportingMediaValidation';

function SupportingMediaForm(props: SupportingMediaFormPropTypes) {
	const { supportingMedias, assistances, setNotification, loading, setLoading, modal, onClose, supportingMedia, addSupportingMedia, updateSupportingMedia, setSupportingMedia } = props;

	const [url, setUrl] = useState(null);
	const [urlError, setUrlError] = useState(false);
	const [uploadPercentage, setUploadPercentage] = useState(null);
	const [typeError, setTypeError] = useState(false);

	const getSignedUrl = async (file: any) => {
		try {
			const filename = file.name.replace(/\s/g, '');
			const res = await http.getSignedUrl(filename, file.type);
			const config = {
					onUploadProgress: function(progressEvent: any) {
							var percentCompleted = Math.round(
									(progressEvent.loaded * 100) / progressEvent.total
							);
							setUploadPercentage(percentCompleted > 0 && percentCompleted - 1);
						},
					header: {
							'Content-Type': file.type
					}
				};
				
			await axios.put(res.data.data, file, config);
			return filename;	
		} catch (err) {
			console.log(err)
			return false;
		}
	}
	
	const formik = useFormik({
			initialValues: {
				assistanceId: supportingMedia?.assistanceId || "",
				name: supportingMedia?.name || ""
			},
			validationSchema: AssistanceMediaValidation,
			enableReinitialize: true,
			onSubmit: async (values, { resetForm }) => {
				if(supportingMedia === undefined || Object.keys(supportingMedia).length === 0) {

					const isValidUrl = url !== null;
					setUrlError(!isValidUrl);

					if(isValidUrl){
						setLoading(true);
						try {
							const url_filename = await getSignedUrl(url);
		
							if(url_filename) {
									const data = { ...values, url: url_filename, assistanceId: parseInt(values.assistanceId) };
									addSupportingMedia(data).then(() => {
										setUploadPercentage(100);
										setTimeout(() => {
											setLoading(false);
											onClose();
											setUploadPercentage(null);
											resetForm();
											setUrl(null);
											setNotification({
												isVisible: true,
												heading: 'Success',
												text: `Supporting Media has been added successfully`
											});
											setTimeout(() => {
												setNotification({isVisible: false, heading: '', text: ''})
											}, 10000);
										}, 500);
									});
								}
						} catch(err) {
							setLoading(false)
						}
					}
				} else {
					if(supportingMedia?.url || url) {
						let data: any = {...values, assistanceId: parseInt(values.assistanceId)}
						setLoading(true);
						try {
							const updatedSupportingMedia = supportingMedias?.data.filter((sm: any) => sm.id === supportingMedia.id)[0]
							const urlFileName = getFileNameFromUrl(updatedSupportingMedia.url);
					
							if(url) {
								await http.deleteS3Image(urlFileName);
								const url_filename = await getSignedUrl(url);
								data = {...data, url: url_filename}
							} 
							
							updateSupportingMedia(supportingMedia.id, data).then(() => {
								setUploadPercentage(100);
								setTimeout(() => {
									setLoading(false);
									onClose();
									setUploadPercentage(null);
									resetForm();
									setUrl(null);
									setNotification({
										isVisible: true,
										heading: 'Success',
										text: `Supporting Media has been updated successfully`
									});
									setTimeout(() => {
										setNotification({isVisible: false, heading: '', text: ''})
									}, 10000);
									setSupportingMedia({});
								}, 500);
							});
						} catch(err) {
							setLoading(false)
						}
					}
				}
			},
	});

	const fileChangeHandler = (e: any) => {
		if(assistances.data.filter((assis: any) => assis.id === parseInt(formik.values.assistanceId))[0]?.type  === 'AUDIO' && isVideo(e.target.files[0].name)) {
			setUrl(null); setUrlError(true);
			setTypeError(true);
			return false;
		} else {
			setUrl(e.target.files[0]); setUrlError(false);
		}
	}

	return (
		<Modal onClose={onClose} size='4xl' isOpen={modal}>
			<ModalOverlay />
			<ModalContent>
				<form onSubmit={formik.handleSubmit}>
					<ModalHeader>{ (supportingMedia === undefined || Object.keys(supportingMedia).length === 0) ? "Add " : "Update " } Supporting Media</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
							<SimpleGrid columns={{ sm: 1, md: 2 }} spacing={{ base: '20px', xl: '20px' }}>
								<div>
									<InputField 
										style={(formik.touched.name && formik.errors.name) && { border: '1.5px solid red' }} mb='0px' me='30px' 
										{...formik.getFieldProps('name')} 
										id='name' 
										label='Supporting Media Name' 
										placeholder='Supporting Media Name' 
									/>
									{formik.touched.name && formik.errors.name ? (
										<div className="error-message">{formik.errors.name}</div>
									) : null}
								</div>
								<div>
									<label htmlFor='assistanceId' style={{ marginInlineEnd: '0.75rem', display: 'flex', textAlign: 'start', fontSize: '0.875rem', fontWeight: '700', marginInlineStart: '10px', marginBottom: '0.5rem' }}>Assistance</label>
									<Select 
										placeholder='Select Assistance'
										style={(formik.touched.assistanceId && formik.errors.assistanceId) && { border: '1.5px solid red' }} mb='0px' me='30px' 
										{...formik.getFieldProps('assistanceId')} 
										id='assistanceId'  
									>
										{assistances?.data?.map((item: any, key: any) => (
											<option key={key} value={item.id}>{item.type + " : " + getFileNameFromUrl(item.mediaUrl)}</option>
										))}
									</Select>
									{formik.touched.assistanceId && formik.errors.assistanceId ? (
										<div className="error-message">{formik.errors.assistanceId}</div>
									) : null}
								</div>
							</SimpleGrid>
							<br/>
						<SimpleGrid columns={{ sm: 1, md: 1 }} spacing={{ base: '20px', xl: '20px' }}>	
							<div>
								<InputField 
									style={(urlError || ((!(url || supportingMedia?.url) && formik.submitCount > 0))) ? { border: '1.5px solid red', padding: '8px' } : { padding: '8px' }} mb='0px' me='30px' 
									type='file'
									accept={assistances.data.filter((assis: any) => assis.id === parseInt(formik.values.assistanceId))[0]?.type  === 'AUDIO' ? "audio/*" : "video/*"}
									id='filename' 
									label='Supporting Media' 
									placeholder='Supporting Media' 
									onChange={fileChangeHandler}
								/>
								{(!(url || supportingMedia?.url) && formik.submitCount > 0) ? (
									<div className="error-message">{ typeError ? "Please upload an audio file" : "Media is required"}</div>
								) : null}
							</div>
							</SimpleGrid>
					</ModalBody>
					<ModalFooter>
						<Button onClick={() => { setUrl(null); onClose()}}>Cancel</Button>
						<Button ml={2} isLoading={loading} loadingText={(supportingMedia === undefined || Object.keys(supportingMedia).length === 0) ? `Saving ${uploadPercentage ? ("( " + uploadPercentage + "% )") : "" }` : `Updating ${uploadPercentage ? ("( " + uploadPercentage + "% )") : ""}`} colorScheme='blue' type='submit'>Save</Button>
					</ModalFooter>
				</form>
			</ModalContent>
		</Modal>
	);
}

const mapDispatchToProps = {
  addSupportingMedia,
	updateSupportingMedia
}

export default connect(null, mapDispatchToProps)(SupportingMediaForm);