import { Icon } from '@chakra-ui/react';
import { MdHome, MdLock, MdBreakfastDining, MdFeaturedVideo, MdFeed } from 'react-icons/md';

import SignInDefault from 'views/auth/signIn/SignInDefault';
import SignUpDefault from 'views/auth/signUp/SignUpDefault';
import MuseumList from 'views/admin/museums/list';
import BeaconList from 'views/admin/beacons/list';
import AssistanceList from 'views/admin/assistances/list';
import SupportingMediaList from 'views/admin/supportingMedias/list';
import ExhibitList from 'views/admin/exhibits/list';
import MediaLibrary from 'views/admin/media-library';

const routes = [
	// --- Dashboards ---
	{
		name: 'Exhibits',
		path: '/exhibits',
		icon: <Icon as={MdBreakfastDining} width='20px' height='20px' color='inherit' />,
		layout: '/admin',
		exact: false,
		component: ExhibitList
	},
	{
		name: 'Beacons',
		path: '/beacons',
		icon: <Icon as={MdBreakfastDining} width='20px' height='20px' color='inherit' />,
		layout: '/admin',
		exact: false,
		component: BeaconList
	},
	{
		name: 'Assistances',
		path: '/assistances/:id',
		icon: <Icon as={MdFeaturedVideo} width='20px' height='20px' color='inherit' />,
		layout: '/admin',
		exact: false,
		component: AssistanceList
	},
	{
		name: 'Supporting Media',
		path: '/supporting-media',
		icon: <Icon as={MdFeed} width='20px' height='20px' color='inherit' />,
		layout: '/admin',
		exact: false,
		component: SupportingMediaList
	},
	{
		name: 'Manage Museums',
		path: '/museums',
		icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
		layout: '/admin',
		exact: false,
		component: MuseumList
	},
	{
		name: 'Media Library',
		path: '/media-library',
		icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
		layout: '/admin',
		exact: false,
		component: MediaLibrary
	},
	// --- Authentication ---
	{
		name: 'Authentication',
		path: '/auth',
		icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
		collapse: true,
		items: [
			// --- Sign In ---
			{
				name: 'Sign In',
				path: '/sign-in',
				collapse: true,
				items: [
					{
						name: 'Default',
						layout: '/auth',
						path: '/sign-in/default',
						component: SignInDefault
					}
				]
			},
			// --- Sign Up ---
			{
				name: 'Sign Up',
				path: '/sign-up',
				collapse: true,
				items: [
					{
						name: 'Default',
						layout: '/auth',
						path: '/sign-up/default',
						component: SignUpDefault
					}
				]
			}
		]
	}
];

export default routes;
