import axios from 'axios'
const token = window.localStorage.getItem('a4a_access_token')

const instance = axios.create({
  // baseURL: "http://localhost:3000/api/v1"
  // baseURL: "https://staging.api.a4acontent.com/api/v1",
  baseURL: "https://www.api.a4acontent.com/api/v1",
})

if (token) {
  instance.defaults.headers.common['authorization'] = "bearer " + token
}

instance.interceptors.response.use(function (response) {
  // Do something with response data
  return response
}, function (error) {
  // Do something with response error
  if (error && error.response && error.response.status === 401) {
      window.localStorage.clear();
      window.location.href = "/auth/sign-in/default"
  }
  return Promise.reject(error)
})

instance.defaults.headers.post['Content-Type'] = 'application/json'

export default instance  