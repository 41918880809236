import React, { useEffect, useState } from 'react';

export const RootContext = React.createContext({
	user: {},
	setUser: (arg: any) => {},
	museum: "",
	setMuseum: (arg: any) => {}
});

// @ts-ignore
// eslint-disable-next-line import/no-anonymous-default-export
export default ({ children }) => {
	const prevUser = (window.localStorage.getItem('a4a_user') && JSON.parse(window.localStorage.getItem('a4a_user'))) || "";
	const prevMuseum = (window.localStorage.getItem('a4a_museum') && JSON.parse(window.localStorage.getItem('a4a_museum'))) || "";

	const [user, setUser] = useState(prevUser);
	const [museum, setMuseum] = useState(prevMuseum);

	useEffect(
		() => {
			if (user && Object.keys(user).length > 0) window.localStorage.setItem('a4a_user', JSON.stringify(user));
			if (museum) window.localStorage.setItem('a4a_museum', JSON.stringify(museum));
		},
		[user, museum]
	);

	const defaultContext = {
		setUser, user, museum, setMuseum
	};

	return (
		// @ts-ignore
		<RootContext.Provider value={defaultContext}>
			{children}
		</RootContext.Provider>
	);
};