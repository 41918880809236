// Chakra imports
import { Flex } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import http from 'services';
import { getMuseumsList } from 'store/Actions/MuseumActions';
import { getBeaconsList } from 'store/Actions/BeaconActions';
import MuseumForm from '../form';
import MuseumsTable from './components/MuseumsTable';
import { museumsColumns } from './variables/museumColumns';
import { deleteMuseum } from '../../../../store/Actions/MuseumActions';
import Notification from 'components/notification/Notification';
import { getFileNameFromUrl } from 'helpers/functions';
import { RootContext } from 'contextApi';
import { getLookupsList } from 'store/Actions/LookupActions';

// @ts-ignore
const MuseumList = ({ getMuseumsList, museums, deleteMuseum, getBeaconsList, getLookupsList, lookups }) => {

	const [modal, setModal] = useState(false);
	const [museum, setMuseum] = useState({});
	const [loading, setLoading] = useState(false);
	const [notification, setNotification] = useState({ isVisible: false, heading: "", text: "" });
	const [loadingData, setLoadingData] = useState(false);
	const { museum: selectedMuseum, setMuseum: setSelectedMuseum } = useContext(RootContext);

	useEffect(() => {
		if(museums?.data.length === 0) {
			setLoadingData(true);
			getMuseumsList().then((res: any) => {
				setLoadingData(false);
			});
		}
		if(lookups && Object.keys(lookups).length === 0) {
			getLookupsList();
		} 
	}, [getLookupsList, getMuseumsList, lookups, museums?.data.length]);

	const data:any = [];
	museums?.data.forEach((item: any) => {
		const tempItem = JSON.parse(JSON.stringify(item));
		tempItem.name = [item.name, item.small_logo];
		tempItem.actions = item.id;
		data.push(tempItem);
	})

	const editMuseumHandler = (id: number) => {
		setMuseum(museums.data.filter((item: any) => item.id === id)[0]);
	}

	const deleteMuseumHandler = (id: number) => {
		try {
			const museum = museums.data.filter((item: any) => item.id === id)[0]
			setMuseum(museum);
			setLoading(true);
			deleteMuseum(id).then(() => {
				const smallLogoFileName = getFileNameFromUrl(museum.small_logo);
				const largeLogoFileName = getFileNameFromUrl(museum.large_logo);
				http.deleteS3Image(smallLogoFileName).then(res => {
					http.deleteS3Image(largeLogoFileName).then(res => {
						getBeaconsList(selectedMuseum);
						// @ts-ignore
						if(parseInt(selectedMuseum) === parseInt(museum.id)) {
							if(museums.data.length > 0) {
								setSelectedMuseum(museums.data.filter((mus: any) => mus.id !== selectedMuseum)[0].id);
							}
						}
						setLoading(false);
						setNotification({
							isVisible: true,
							heading: 'Success',
							text: `${museum.name} has been deleted successfully`
						});
						setTimeout(() => {
							setNotification({isVisible: false, heading: '', text: ''})
						}, 10000);
					});
				});
			});
		} catch(err) {
			setLoading(false);
		}
	}

	return (
		<Flex direction='column' pt={{ sm: '125px', lg: '75px' }}>
			<Card px='0px'>
				<Notification 
					isVisible={notification.isVisible}
					heading={notification.heading}
					text={notification.text}
				/>
				<MuseumsTable 
					tableData={data} 
					columnsData={museumsColumns} 
					onOpen={() => setModal(true)}
					setMuseum={editMuseumHandler}
					deleteMuseumHandler={deleteMuseumHandler}
					loading={loading}
					museum={museum}
					loadingData={loadingData}
				/>
			</Card>
			<MuseumForm 
				modal={modal} 
				onClose={() => setModal(false)} museum={museum} setMuseum={setMuseum} 
				loading={loading}
				setLoading={setLoading}
				setNotification={setNotification}
				museums={museums}
				lookups={lookups}
			/>
		</Flex>
	);
}

const mapStateToProps = (state: any) => ({
  museums: state.museums,
	lookups: state.lookups
})

const mapDispatchToProps = {
  getMuseumsList,
	deleteMuseum,
	getBeaconsList,
	getLookupsList
}

export default connect(mapStateToProps, mapDispatchToProps)(MuseumList);