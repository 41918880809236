import { RootContext } from 'contextApi';
import { isEmptyObject } from 'helpers/functions';
import { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';

// @ts-ignore
// eslint-disable-next-line import/no-anonymous-default-export
export default ({ children, ...routeProps }) => {

	const { user } = useContext(RootContext);

	return (
		<Route
			{...routeProps}
			render={() => (!isEmptyObject(user) ?
				(
					children
				) :
				<Redirect to='/auth' />)
			}
		/>
	);
};