
import { applyMiddleware, compose, createStore } from 'redux'
import { composeWithDevTools } from '@redux-devtools/extension'
import thunk from 'redux-thunk'
import rootReducer from './Reducers'

const composeEnhancers = composeWithDevTools(applyMiddleware(thunk))
const store = createStore(rootReducer, compose(composeEnhancers))

export default store
