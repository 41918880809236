import http from 'services';
import { ADD_ASSISTANCE, ADD_SUPPORTING_MEDIA, DELETE_ASSISTANCE, DELETE_SUPPORTING_MEDIA, EDIT_ASSISTANCE, GET_ALL_ASSISTANCES, RESET_ASSISTANCE } from './types'

export const getAssistancesList = (beaconId: any) => async (dispatch: any) => {
	try {
		const response = await http.getAssistances(beaconId);
		
		if( response?.data?.data.length > 0 ){
			dispatch({
				type: GET_ALL_ASSISTANCES,
				payload: response?.data?.data
			})
		}else {
			dispatch({
				type: GET_ALL_ASSISTANCES,
				payload: []
			})
		}
		return response;
	} catch (err) {
    console.log("ERROR => ", err);
	}
}

export const addAssistance = (data: any) => async (dispatch: any) => {
	try {
		const response = await http.createAssistance(data);
	
		dispatch({
			type: ADD_ASSISTANCE,
			payload: response.data.data
		})
		return response;
	} catch (err) {
    console.log("ERROR => ", err);
	}
}

export const updateAssistance = (id: number, data: any) => async (dispatch: any) => {
	try {
		const response = await http.updateAssistance(id, data);

		const result = response.data.data;

		dispatch({
			type: EDIT_ASSISTANCE,
			payload: {
				id: id,
				data: result
			}
		})
		return response;
	} catch (err) {
    console.log("ERROR => ", err);
	}
}

export const deleteAssistance = (id: number) => async (dispatch: any) => {
	try {
		const response = await http.deleteAssistance(id);
		
		dispatch({
			type: DELETE_ASSISTANCE,
			payload: id
		})
		return response;
	} catch (err) {
    console.log("ERROR => ", err);
	}
}

export const addAssistanceSupportingMedia = (data: any) => async (dispatch: any) => {
	try {
		const response = await http.createSupportingMedia(data);
	
		dispatch({
			type: ADD_SUPPORTING_MEDIA,
			payload: response.data.data
		})
		return response;
	} catch (err) {
    console.log("ERROR => ", err);
	}
}

export const deleteAssistanceSupportingMedia = (assistanceId: number, suppMediaId: number) => async (dispatch: any) => {
	try {
		const response = await http.deleteSupportingMedia(suppMediaId);
		
		dispatch({
			type: DELETE_SUPPORTING_MEDIA,
			payload: {
				assistanceId,
				suppMediaId
			}
		})
		return response;
	} catch (err) {
    console.log("ERROR => ", err);
	}
}

export const clearAssistance = () => ({
	type: RESET_ASSISTANCE,
})