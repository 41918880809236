export const museumsColumns = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "IP Address",
    accessor: "ipAddress",
  },
  {
    Header: "Contact Info",
    accessor: "contactInfo",
  },
  {
    Header: "Address",
    accessor: "address",
  },
  {
    Header: "Large Logo",
    accessor: "large_logo",
  },
  {
    Header: "Actions",
    accessor: "actions",
  },
];
