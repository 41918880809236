import { GET_ALL_LOOKUPS } from 'store/Actions/types'
import reduxActionType from '../../variables/reduxActionType'

const initialState = {}

function LookupReducer (state = initialState, action: reduxActionType) {
  switch (action.type) {
    case GET_ALL_LOOKUPS: {
      return action.payload
    }
    default:
      return state
  }
}

export default LookupReducer
