import { Provider } from 'react-redux'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import store from './store'
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/admin';
import RTLLayout from './layouts/rtl';
import RootContext from './contextApi';

function App(): JSX.Element {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <RootContext>
          <Switch>
            <Route path={`/auth`} component={AuthLayout} />
            <Route path={`/admin`} component={AdminLayout} />
            <Route path={`/rtl`} component={RTLLayout} />
            <Redirect from='/' to='/admin/beacons' />
          </Switch>
        </RootContext>
      </Provider>
    </BrowserRouter>
  )
}

export default App
