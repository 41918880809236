// Chakra imports
import { Button, Flex, Input, useColorModeValue } from '@chakra-ui/react';
import axios from 'axios';
import { uploadToS3 } from 'helpers/functions';
import { ReactNode, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import http from 'services';

export interface IFile {
  name: string;
  url: string;
  content_type: string;
  fileId?: string;
  id?: string;
  isUploaded?: boolean;
  setMediaUploading: (status: boolean) => void;
  mediaUploading?: boolean;
}

function Dropzone(props: { content: JSX.Element | string; [x: string]: any, files: any[], setFiles: any, fileList: IFile[], setFileList: any, onFileUpload: any, mediaUploading: boolean, setMediaUploading: any }) {
	const { content, files, setFiles, fileList, setFileList, onFileUpload, setMediaUploading, mediaUploading, ...rest } = props;
  
  const acceptedFileTypes = ['audio/*', 'video/*'];

	const onDrop = useCallback(async (acceptedFiles) => {
		try {
      const promises: any[] = [];
      setMediaUploading(true);
			const splicedIndex: number[] = [];
      acceptedFiles.forEach(async (file: any, index: number) => {
        if (file.type.includes('video') || file.type.includes('audio')) {
          const reader = new FileReader();
          
          reader.onloadend = () => {
            file.url = reader.result;
            file.content_type = file.type;
            file.fileObject = file;
            setFiles((prevFiles: any) => [...prevFiles]);
          };
          reader.readAsDataURL(file);
  
          file.progress = 0;
          const source = axios.CancelToken.source();
          file.abortController = source;
          setFiles((prevFiles: any) => [...prevFiles, file]);
          file.id = 1 + index;
  
          const config = {
            onUploadProgress: (event: any) => {
              file.progress = Math.round((event.loaded / event.total) * 100);
              if (file.progress === 100) {
                setFiles((prevFiles: any) => prevFiles.filter((f: any) => f !== file));
                file.isUploaded = true;
                file.signedUrl = file.url;
                if(!(fileList.filter((f) => f.name === file.name).length > 0)) {
                  fileList.push(file);                                
                } 
                setFileList([...fileList]);
              }
              setFiles((prevFiles: any) => [...prevFiles]);
            },
						header: {
							'Content-Type': file.type
						}
          };
  
          let imgObj = {
            file_name: file.name,
            content_type: file.type,
          };
  
          const promise = getSignUrlAndUploadToAWS(
            imgObj,
            config,
						file,
						source.token
          );
          promises.push(promise);
          return;
        }
        splicedIndex.push(index);
      });

      let res = await Promise.all(promises);
      const newlyAddedFiles = fileList.filter(file => file !== undefined && (Object(file).hasOwnProperty('abortController') && (Object(file).hasOwnProperty('id') && typeof file.id === 'number')));
      if (res.length === acceptedFiles.length - splicedIndex.length) {
        let filesObj = res
          .map((item, i) => {
						if (item.status === 'failed') {
              return;
            }
            const valueToBeReturned = {
              name: item.name,
              data: item.data.data,
              url: item.data.url,
							content_type: item.content_type
            };
              newlyAddedFiles[i].id = item.data.fileId;
            return valueToBeReturned;
          })
          .filter((f) => f !== undefined);
          onFileUpload(filesObj);
      }
    } catch (err) { 
			console.log('error => ', err);
    }
  }, [files, setFiles]);

	const getSignUrlAndUploadToAWS = async (
    imgObj: any, config: any, file: any, controller: any
  ) => {
		try {
				const filename = file.name.replace(/\s/g, '');
				const res = await http.getSignedUrl(filename, file.type);
				
				const uploadFileResponse = await uploadToS3(
					res.data.data,
					file,
					config,
					controller
				);
				if (uploadFileResponse) {
					res.data.signedUrl = file.url;
					return { ...res, status: 'success', content_type: file.content_type, name: file.name };
				}
				return { status: 'failed' };

			} catch (err) {
				console.log(err)
				return false;
			}	
  };

	const { getRootProps, getInputProps, acceptedFiles } = useDropzone({onDrop, accept: acceptedFileTypes.join(',')});
	const bg = useColorModeValue('gray.100', 'navy.700');
	const borderColor = useColorModeValue('gray.300', 'whiteAlpha.100');

	return (
		<Flex
      style={mediaUploading ? { pointerEvents: 'none' } : {}}
			align='center'
			justify='center'
			bg={bg}
			border='1px dashed'
			borderColor={borderColor}
			borderRadius='16px'
			w='100%'
			maxW='100%'
			h={{ base: '208px', '3xl': '300px' }}
			cursor='pointer'
			{...getRootProps({ className: 'dropzone' })}
			{...rest}>
			<input {...getInputProps()} />
			<Button variant='no-effects'>{content}</Button>
		</Flex>
	);
}

export default Dropzone;
