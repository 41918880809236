import { Box, Select } from '@chakra-ui/react'
import { RootContext } from 'contextApi'
import Storage from 'helpers/localstorage'
import { useContext, useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getMuseumsList } from 'store/Actions/MuseumActions'

function MuseumSelector({ getMuseumsList, museums }: { getMuseumsList: any, museums: any }) {

  const { museum, setMuseum } = useContext(RootContext);
  const history = useHistory();

  useEffect(() => {
    if(museums?.data.length === 0) {
      getMuseumsList().then((res: any) => {
        if(!Storage.get("museum")) {
          setMuseum(res.data.data[0].id);
        }
      });
    }
  }, [getMuseumsList, museums, setMuseum]);

  return (
    <>
      <h1 style={{fontWeight: '700', marginLeft: '0.5rem'}}> Selected Museum:  </h1>
      <Box ms='auto' w={{ sm: '100%', md: 'unset' }} style={{ marginRight: '0.5rem', marginLeft: '1rem', border: '1px solid blue', borderRadius: "10px" }}>
        <Select 
          placeholder='Select Museum' mb='0px' me='30px' 
          id='museumId'
          // @ts-ignore
          value={museum}
          onChange={(e) => { setMuseum(e.target.value); Storage.get("museum") }}
        >
          {museums?.data?.map((item: any, key: any) => (
            <option key={key} value={item.id}>{item.name}</option>
          ))}
        </Select>
      </Box>
    </>
    
  )
}

const mapStateToProps = (state: any) => ({
	museums: state.museums,
})

const mapDispatchToProps = {
	getMuseumsList,
}

export default connect(mapStateToProps, mapDispatchToProps)(MuseumSelector)