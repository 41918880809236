import { combineReducers } from 'redux'
import AssistanceReducer from './AssistanceReducer'
import BeaconReducer from './BeaconReducer'
import ExhibitReducer from './ExhibitReducer'
import LookupReducer from './LookupReducer'
import MuseumReducer from './MuseumReducer'
import SupportingMediaReducer from './SupportingReducer'

const rootReducer = combineReducers({
  museums: MuseumReducer,
  beacons: BeaconReducer,
  lookups: LookupReducer,
  assistances: AssistanceReducer,
  supportingMedias: SupportingMediaReducer,
  exhibits: ExhibitReducer
})

export default rootReducer
