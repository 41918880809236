import http from 'services';
import { GET_ALL_LOOKUPS } from './types'

export const getLookupsList = () => async (dispatch: any) => {
	try {
		const response = await http.getLookups();
		
		dispatch({
			type: GET_ALL_LOOKUPS,
			payload: response.data.data
		})
		return response;
	} catch (err) {
    console.log("ERROR => ", err);
	}
}