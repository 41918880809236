import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, useColorModeValue, FormLabel, Select, Switch, useToast } from '@chakra-ui/react';
import InputField from 'components/fields/InputField';
import { ExhibitFormPropTypes } from 'types/propTypes';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import { addExhibit, updateExhibit } from '../../../../store/Actions/ExhibitActions'
import { useContext } from 'react';
import { RootContext } from 'contextApi';
import http from 'services';
import * as Yup from 'yup'

function ExhibitForm(props: ExhibitFormPropTypes) {
	const { exhibits, setNotification, museums, loading, setLoading, modal, onClose, exhibit, addExhibit, updateExhibit } = props;
	const { museum } = useContext(RootContext);
	const toast = useToast();

	const formik = useFormik({
			initialValues: {
				exhibitName: exhibit?.exhibitName || ""
			},
			validationSchema: Yup.object().shape({
				exhibitName: Yup.string().required("Exhibit Name is required")
			}),
			enableReinitialize: true,
			onSubmit: async (values, { resetForm, setErrors }) => {
				if(!museum) {
					toast({
						title: `Please select a museum to modify its beacon!`,
						position: 'top',
						status: 'warning',
						isClosable: true
					});
					return false;
				}
				
				// @ts-ignore
				const newValues = { ...values, museumId: parseInt(museum) }
				setLoading(true);
					try {
						if(exhibit === undefined || Object.keys(exhibit).length === 0) {
							await addExhibit(newValues);
						} else {
							await updateExhibit(exhibit.id, newValues);
						}
						setLoading(false);
						resetForm();
						onClose();
						setNotification({
							isVisible: true,
							heading: 'Success',
							text: `${newValues.exhibitName} has been ${(exhibit === undefined || Object.keys(exhibit).length === 0) ? "Saved " : "Updated "} successfully`
						});
						setTimeout(() => {
							setNotification({isVisible: false, heading: '', text: ''})
						}, 10000);
						
					} catch (err) {
						console.log("ress => ", err);
						// @ts-ignore
						console.log("ress => ", err.response.data);
						// @ts-ignore
						console.log("ress => ", err.response);
					}
			}
	});

	return (
		<Modal onClose={onClose} size='4xl' isOpen={modal} closeOnOverlayClick={false}>
			<ModalOverlay />
			<ModalContent>
				<form onSubmit={formik.handleSubmit}>
					<ModalHeader>{(exhibit === undefined || Object.keys(exhibit).length === 0) ? "Add " : "Update "} Exhibit</ModalHeader>
					<ModalCloseButton onClick={() => formik.resetForm()} />
					<ModalBody>
							<SimpleGrid columns={{ sm: 1, md: 1 }} spacing={{ base: '20px', xl: '20px' }}>
								<div>
									<InputField 
										style={(formik.touched.exhibitName && formik.errors.exhibitName) && { border: '1.5px solid red' }} mb='0px' me='30px' 
										{...formik.getFieldProps('exhibitName')} 
										id='name' 
										label='Exhibit Name' 
										placeholder='Exhibit Name' 
									/>
									{formik.touched.exhibitName && formik.errors.exhibitName ? (
										<div className="error-message">{formik.errors.exhibitName}</div>
									) : null}
								</div>
							</SimpleGrid>
					</ModalBody>
					<ModalFooter>
						<Button onClick={() => { onClose(); formik.resetForm();}}>Cancel</Button>
						<Button ml={2} isLoading={loading} loadingText='Loading...' colorScheme='blue' type='submit'>Save</Button>
					</ModalFooter>
				</form>
			</ModalContent>
		</Modal>
	);
}

const mapDispatchToProps = {
  addExhibit,
	updateExhibit
}

export default connect(null, mapDispatchToProps)(ExhibitForm);