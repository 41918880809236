import { ADD_ASSISTANCE, ADD_SUPPORTING_MEDIA, DELETE_ASSISTANCE, DELETE_SUPPORTING_MEDIA, EDIT_ASSISTANCE, GET_ALL_ASSISTANCES, RESET_ASSISTANCE } from 'store/Actions/types'
import reduxActionType from '../../variables/reduxActionType'

const initialState = {
  data: [] as []
}

function AssistanceReducer (state = initialState, action: reduxActionType) {
  switch (action.type) {
    case GET_ALL_ASSISTANCES: {
      return {
        ...state,
        data: action.payload
      }
    }
    case ADD_ASSISTANCE: {
      return {
        ...state,
        data: [action.payload, ...state.data]
      }
    }
    case EDIT_ASSISTANCE: {
      return {
        ...state,
        data: state.data.map(
          (assistance: any, i) => assistance.id === action.payload.id ? { ...assistance, ...action.payload.data }
              : assistance
        )
      }
    }
    case DELETE_ASSISTANCE: {
      return {
        ...state,
        data: state.data.filter((assistance: any) => assistance.id !== action.payload)
      }
    }
    case ADD_SUPPORTING_MEDIA: {
      return {
        ...state,
        data: state.data.map(
          (assistance: any, i) => assistance.id === action.payload.assistanceId ? { ...assistance, supportingMedias: [...assistance.supportingMedias, action.payload] }
              : assistance
        )
      }
    }
    case DELETE_SUPPORTING_MEDIA: {
      return {
        ...state,
        data: state.data.map(
          (assistance: any, i) => assistance.id === action.payload.assistanceId ? { ...assistance, supportingMedias: assistance.supportingMedias.filter((sm: any) => sm.id !== action.payload.suppMediaId) }
              : assistance
        )
      }
    }
    case RESET_ASSISTANCE: {
      return {
        ...state,
        data: []
      }
    }
    default:
      return state
  }
}

export default AssistanceReducer;
