import http from 'services';
import { ADD_BEACON, DELETE_BEACON, EDIT_BEACON, GET_ALL_BEACONS } from './types'

export const getBeaconsList = (museum: any) => async (dispatch: any) => {
	try {
		const response = await http.getBeacons(museum);
		
		if(response.data.data.length > 0) {
			dispatch({
				type: GET_ALL_BEACONS,
				payload: response.data.data
			})
		} else {
			dispatch({
				type: GET_ALL_BEACONS,
				payload: []
			})
		}


		return response;
	} catch (err) {
    console.log("ERROR => ", err);
	}
}

export const addBeacon = (data: any) => async (dispatch: any) => {
	try {
		const response = await http.createBeacon(data);
		
		dispatch({
			type: ADD_BEACON,
			payload: data
		})
		return response;
	} catch (err) {
    console.log("ERROR => ", err);
	}
}

export const updateBeacon = (id: number, data: any) => async (dispatch: any) => {
	try {
		const response = await http.updateBeacon(id, data);
		
		dispatch({
			type: EDIT_BEACON,
			payload: {
				id: id,
				data: response.data.data
			}
		})
		return response;
	} catch (err) {
    console.log("ERROR => ", err);
	}
}

export const deleteBeacon = (id: number) => async (dispatch: any) => {
	try {
		const response = await http.deleteBeacon(id);
		
		dispatch({
			type: DELETE_BEACON,
			payload: id
		})
		return response;
	} catch (err) {
    console.log("ERROR => ", err);
	}
}