import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  CloseButton,
  Flex,
} from '@chakra-ui/react';

export default function Notification({ heading, isVisible, text }: { text: string, heading: string, isVisible: boolean }) {
  return (
    <>
      {isVisible && (
          <Alert status='success' className="table-notification">
            <AlertIcon />
            <Box>
              <AlertTitle>{heading}!</AlertTitle>
              <AlertDescription>
                {text}
              </AlertDescription>
            </Box>
          </Alert>
      )}
    </>
  );
}