import axios from '../interceptors/axios'
const http = {
  // AUTHENTICATION SERVICES
  login: async (body: any): Promise<any> => await axios.post('/auth/login', body),

  // MUSEUMS SERVICES
  getMuseums: async (): Promise<any> => await axios.get('admin/museums'),
  createMuseum: async (data: any): Promise<any> => await axios.post(`admin/museums`, data),
  updateMuseum: async (id: number, data: any): Promise<any> => await axios.patch(`admin/museums/${id}`, data),
  deleteMuseum: async (id: number): Promise<any> => await axios.delete(`admin/museums/${id}`),

  // EXHIBIT SERVICES
  getExhibits: async (id: number): Promise<any> => await axios.get(`admin/exhibits?museumId=${id}`),
  createExhibit: async (data: any): Promise<any> => await axios.post(`admin/exhibits`, data),
  updateExhibit: async (id: number, data: any): Promise<any> => await axios.patch(`admin/exhibits/${id}`, data),
  deleteExhibit: async (id: number): Promise<any> => await axios.delete(`admin/exhibits/${id}`),
  
  // S3 SIGNED URL SERVICES
  getSignedUrl: async (filename: string, fileType: string): Promise<any> => await axios.get(`admin/assistances/getSignedUrl?filename=${filename}&fileType=${fileType}`),
  getSignedFile: async (filename: string): Promise<any> => await axios.get(`admin/assistances/getSignedFile?filename=${filename}`),
  deleteS3Image: async (filename: string): Promise<any> => await axios.get(`admin/assistances/deleteS3File?filename=${filename}`),

  // BEACONS SERVICES
  getAllBeacons: async (): Promise<any> => await axios.get(`admin/beacons`),
  getBeacons: async (museum: any): Promise<any> => await axios.get(`admin/beacons?museumId=${museum}`),
  createBeacon: async (data: any): Promise<any> => await axios.post(`admin/beacons`, data),
  updateBeacon: async (id: number, data: any): Promise<any> => await axios.patch(`admin/beacons/${id}`, data),
  deleteBeacon: async (id: number): Promise<any> => await axios.delete(`admin/beacons/${id}`),

  // BEACONS SERVICES
  getAssistances: async (beaconId: any): Promise<any> => await axios.get(`admin/assistances?beaconId=${beaconId}`),
  createAssistance: async (data: any): Promise<any> => await axios.post(`admin/assistances`, data),
  updateAssistance: async (id: number, data: any): Promise<any> => await axios.patch(`admin/assistances/${id}`, data),
  deleteAssistance: async (id: number): Promise<any> => await axios.delete(`admin/assistances/${id}`),

  // MEDIA LIBRARY SERVICES
  getMediaLibraries: async (): Promise<any> => await axios.get(`admin/medias`),
  createMediaLibrary: async (data: any): Promise<any> => await axios.post(`admin/medias`, data),
  deleteMediaLibrary: async (id: number): Promise<any> => await axios.delete(`admin/medias/${id}`),

  // ASSISTANCE's SUPPORTING MEDIA SERVICES
  getSupportingMedias: async (): Promise<any> => await axios.get('admin/supportingMedias'),
  createSupportingMedia: async (data: any): Promise<any> => await axios.post(`admin/supportingMedias`, data),
  updateSupportingMedia: async (id: number, data: any): Promise<any> => await axios.patch(`admin/supportingMedias/${id}`, data),
  deleteSupportingMedia: async (id: number): Promise<any> => await axios.delete(`admin/supportingMedias/${id}`),

  // ENUMS SERVICES
  getLookups: async (): Promise<any> => await axios.get('enums')
}

export default http
