import * as Yup from 'yup'

const BeaconValidation = Yup.object().shape({
  uniqueId: Yup.string().required("Unique ID is required"),
	exhibitId: Yup.string().required("Exhibit is required"),
	light: Yup.string().required("Light is required"),
	sound: Yup.string().required("Sound is required"),
	contactInfo: Yup.string(),
})

export default BeaconValidation
