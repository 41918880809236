import axios from 'axios';

const uploadToS3 = (
  signedURL,
  file,
  config,
  signal
) =>
  axios
    .put(signedURL, file, {
      ...config,
      cancelToken: signal,
      // headers: { 'Content-Type': file.type },
    })
    .catch((err) => {});


const isEmptyObject = (object) => {
	return !(object && Object.keys(object || {}).length !== 0);
};

const checkEmptyString = (string) => string !== "";

const isEmptyArray = (array) => (!array || array?.length === 0);

const getFileNameFromUrl = (url) => {
	return url.split("/").pop().split("?")[0]
}

function getExtension(filename) {
  return filename.split(".").pop();
}

function isImage(filename) {
  var ext = getExtension(filename);
  // eslint-disable-next-line default-case
  switch (ext.toLowerCase()) {
    case 'jpg':
    case 'gif':
    case 'bmp':
    case 'png':
      //etc
      return true;
  }
  return false;
}

function isAudioFormatSupported(filename) {
  var ext = getExtension(filename);

  // eslint-disable-next-line default-case
  switch (ext.toLowerCase()) {
    case 'aif':
      return false;
    case 'mp3': 
      return false;
  }
  return true;
}

function isVideo(filename) {
  var ext = getExtension(filename);
  // eslint-disable-next-line default-case
  switch (ext.toLowerCase()) {
    case 'm4v':
    case 'avi':
    case 'mpg':
    case 'mp4':
      // etc
      return true;
  }
  return false;
}

const calculateFileSize = (percentage, currentFile) => {
  if (!!percentage && currentFile) {
    return (
      ((percentage / 100) * (currentFile.size / 1000000)).toFixed(2) +
      'MB out of ' +
      (currentFile?.size / 1000000).toFixed(2) +
      'MB'
    );
  }
  if (currentFile) {
    return (currentFile.size / 1000000).toFixed(2) + 'MB';
  }
  return '0 KB';
};

export { 
	isEmptyObject, 
	checkEmptyString, 
	isEmptyArray,
	getFileNameFromUrl,
	isImage,
	isVideo,
  isAudioFormatSupported,
  uploadToS3,
  calculateFileSize
}
