import http from 'services';
import { ADD_SUPPORTING_MEDIA, DELETE_SUPPORTING_MEDIA, EDIT_SUPPORTING_MEDIA, GET_ALL_SUPPORTING_MEDIAS } from './types'

export const getSupportingMediaList = () => async (dispatch: any) => {
	try {
		const response = await http.getSupportingMedias();
		
		if( response?.data?.data.length > 0 ){
			let data = await Promise.all(response?.data?.data.map(async (item: any) => {
				const tempItem = JSON.parse(JSON.stringify(item));
				const mediaUrlResponse = await http.getSignedFile(item.url);
				tempItem.url = mediaUrlResponse.data.data;
				return tempItem;
			}));

			dispatch({
				type: GET_ALL_SUPPORTING_MEDIAS,
				payload: data
			})
		}
		return response;
	} catch (err) {
    console.log("ERROR => ", err);
	}
}

export const addSupportingMedia = (data: any) => async (dispatch: any) => {
	try {
		const response = await http.createSupportingMedia(data);
		
		const mediaUrlResponse = await http.getSignedFile(data.url);
		const result = response.data.data;
		result.url = mediaUrlResponse.data.data;

		dispatch({
			type: ADD_SUPPORTING_MEDIA,
			payload: result
		})
		return response;
	} catch (err) {
    console.log("ERROR => ", err);
	}
}

export const updateSupportingMedia = (id: number, data: any) => async (dispatch: any) => {
	try {
		const response = await http.updateSupportingMedia(id, data);

		const mediaUrlResponse = await http.getSignedFile(response.data.data.url);

		const result = response.data.data;
		result.url = mediaUrlResponse.data.data;
		
		dispatch({
			type: EDIT_SUPPORTING_MEDIA,
					payload: {
					id: id,
					data: result
				}
			})
		return response;
	} catch (err) {
    console.log("ERROR => ", err);
	}
}

export const deleteSupportingMedia = (id: number) => async (dispatch: any) => {
	try {
		const response = await http.deleteSupportingMedia(id);
		
		dispatch({
			type: DELETE_SUPPORTING_MEDIA,
			payload: id
		})
		return response;
	} catch (err) {
    console.log("ERROR => ", err);
	}
}