const PREFIX = "a4a_";

export default class Storage {
	// Set Local Storage Value
	static set(key, value) {
		window.localStorage.setItem(PREFIX + key, value);
	}

	// Get Local Storage Value
	static get(key) {
		return window.localStorage.getItem(PREFIX + key);
	}

	static delete(key) {
		window.localStorage.removeItem(PREFIX + key);
	}

	static deleteAll() {
		window.localStorage.clear();
	}
}