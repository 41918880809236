// Chakra imports
import { SmallCloseIcon } from '@chakra-ui/icons';
import { Button, Flex, Icon, useColorModeValue } from '@chakra-ui/react';
import { getFileNameFromUrl } from 'helpers/functions';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

// Assets
function Dropzone(props: { uploadPercentage?: number, accept?: string, onFileSelect?: any, setIsUpdateImage?: any, isUpdateImage?: string, isError?: boolean, fileChangeHandler?: any, content: JSX.Element | string; [x: string]: any }) {
	const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
	const { uploadPercentage, accept, onFileSelect, setIsUpdateImage, isUpdateImage, isError, content, fileChangeHandler, ...rest } = props;
	const [removed, setRemoved] = useState(false);

	const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
		maxFiles: 1,
		onDrop: files => { fileChangeHandler(files); setRemoved(false); onFileSelect()},
		noClick: !(imagePreviewUrl === null && !isUpdateImage),
		accept: accept
	});

	const bg = useColorModeValue('gray.100', 'navy.700');
	const borderColor = useColorModeValue('gray.300', 'whiteAlpha.100');
	const bgButton = useColorModeValue('red.200', 'whiteAlpha.100');
	const bgHover = useColorModeValue({ bg: 'red.300' }, { bg: 'whiteAlpha.50' });
	const bgFocus = useColorModeValue({ bg: 'red.300' }, { bg: 'whiteAlpha.100' });
	const iconColor = useColorModeValue('red.700', 'white');

	if(acceptedFiles.length > 0 && removed === false) {
		let reader = new FileReader();
		reader.onloadend = () => {
				// @ts-ignore
				setImagePreviewUrl(reader.result)
		}
		// @ts-ignore
		reader.readAsDataURL(acceptedFiles[0])
	}

	let imageStyles: any = { backgroundSize: '100% 100%' }

	if(isError) {
		imageStyles.border = '1.5px solid red'
	}

	if(imagePreviewUrl) {
		imageStyles.backgroundImage = 'url(' + imagePreviewUrl + ')';
	} else {
		imageStyles.backgroundImage = 'url(' + isUpdateImage + ')'
	}

	if(imagePreviewUrl || isUpdateImage) {
		imageStyles.justifyContent = 'flex-end';
		imageStyles.alignItems = 'baseline';
		imageStyles.padding = '5px';
	}

	return (
		<Flex
			align='center'
			justify='center'
			bg={bg}
			style={imageStyles}
			border='1px dashed'
			borderColor={borderColor}
			borderRadius='16px'
			w='100%'
			maxW='100%'
			h={{ base: '208px', '3xl': '300px' }}
			cursor='pointer'
			{...getRootProps({ className: 'dropzone' })}
			{...rest}>
			<input {...getInputProps()} />
			{imagePreviewUrl === null && !isUpdateImage ? (
				<Button variant='no-effects'>
					{content}
				</Button>
			): (
				<>
				{(accept === "audio/*" || accept === "video/*") && (
					<>
						{uploadPercentage && (
							<CircularProgressbar value={uploadPercentage} text={`${uploadPercentage}%`} />
						)}
						<p style={{ margin: '10px' }}>{isUpdateImage ? getFileNameFromUrl(isUpdateImage) : acceptedFiles[0]?.name}</p>
					</>
				)}
				<Button
            alignItems='center'
            justifyContent='center'
            bg={bgButton}
            _hover={bgHover}
            _focus={bgFocus}
            _active={bgFocus}
						disabled={((accept === "audio/*" || accept === "video/*") && uploadPercentage && uploadPercentage > 0)}
            w='37px'
            h='37px'
            lineHeight='100%'
            borderRadius='10px'
            onClick={() => { setImagePreviewUrl(null); setIsUpdateImage(); setRemoved(true)}}
          >
					<Icon as={SmallCloseIcon} color={iconColor} w='24px' h='24px' />
				</Button>
				</>
			)}
			<br/>
		</Flex>
	);
}

export default Dropzone;
