import http from 'services';
import { ADD_MUSEUM, DELETE_MUSEUM, EDIT_MUSEUM, GET_ALL_MUSEUMS } from './types'

export const getMuseumsList = () => async (dispatch: any) => {
	try {
		const response = await http.getMuseums();
		
		if( response?.data?.data.length > 0 ){
			dispatch({
				type: GET_ALL_MUSEUMS,
				payload: response.data.data
			})
		}
		return response;
	} catch (err) {
    console.log("ERROR => ", err);
	}
}

export const addMuseum = (data: any) => async (dispatch: any) => {
	try {
		const response = await http.createMuseum(data);
		
		const smallLogo = await http.getSignedFile(data.small_logo);
		const largeLogo = await http.getSignedFile(data.large_logo);

		const result = response.data.data;
		result.small_logo = smallLogo.data.data;
		result.large_logo = largeLogo.data.data;

		dispatch({
			type: ADD_MUSEUM,
			payload: result
		})
		return response;
	} catch (err) {
    console.log("ERROR => ", err);
	}
}

export const updateMuseum = (id: number, data: any) => async (dispatch: any) => {
	try {
		const response = await http.updateMuseum(id, data);
	
		const smallLogo = await http.getSignedFile(response.data.data.small_logo);
		const largeLogo = await http.getSignedFile(response.data.data.large_logo);

		const result = response.data.data;
		result.small_logo = smallLogo.data.data;
		result.large_logo = largeLogo.data.data;
		
		dispatch({
			type: EDIT_MUSEUM,
			payload: {
				id: id,
				data: response.data.data
			}
		})
		return response;
	} catch (err) {
    console.log("ERROR => ", err);
	}
}

export const deleteMuseum = (id: number) => async (dispatch: any) => {
	try {
		const response = await http.deleteMuseum(id);
		
		dispatch({
			type: DELETE_MUSEUM,
			payload: id
		})
		return response;
	} catch (err) {
    console.log("ERROR => ", err);
	}
}