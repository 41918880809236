import { Box, Button, Text, Icon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, useColorModeValue, FormLabel, Switch, Select } from '@chakra-ui/react';
import InputField from 'components/fields/InputField';
import { MuseumFormPropTypes } from 'types/propTypes';
import Dropzone from '../../../../components/FileUpload/Dropzone';
import { MdOutlineCloudUpload } from 'react-icons/md';
import { useFormik } from 'formik';
import { useState } from 'react';
import http from 'services';
import axios from 'axios';
import { connect } from 'react-redux';
import { addMuseum, updateMuseum } from '../../../../store/Actions/MuseumActions'
import { getFileNameFromUrl } from 'helpers/functions';
import * as Yup from 'yup'

function MuseumForm(props: MuseumFormPropTypes) {
	const { museums, setNotification, loading, setLoading, modal, onClose, museum, addMuseum, updateMuseum, setMuseum, lookups } = props;

	const [smallLogo, setSmallLogo] = useState(null);
	const [largeLogo, setLargeLogo] = useState(null);
	const [smallLogoError, setSmallLogoError] = useState(false);
	const [largeLogoError, setLargeLogoError] = useState(false);

	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const brand = useColorModeValue('brand.500', 'brand.400');
	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

	const getSignedUrl = async (file: any) => {
		try {
			const filename = file.name.replace(/\s/g, '');
			const res = await http.getSignedUrl(filename, file.type);
			const config = {
				onUploadProgress: function (progressEvent: any) {
					var percentCompleted = Math.round(
						(progressEvent.loaded * 100) / progressEvent.total
					);
					console.log(percentCompleted);
				},
				header: {
					'Content-Type': file.type
				}
			};

			await axios.put(res.data.data, file, config);
			return filename;
		} catch (err) {
			console.log(err)
			return false;
		}
	}

	const formik = useFormik({
		initialValues: {
			name: museum?.name || "",
			address: museum?.address || "",
			ipAddress: museum?.ipAddress || "",
			contactInfo: museum?.contactInfo || "",
			behaviourSupport: museum?.behaviourSupport || false,
			audioDescriptor: museum?.audioDescriptor || false,
			assistiveListening: museum?.assistiveListening || false,
			heartbeatPort: museum?.heartbeatPort || "",
			beaconType: museum?.beaconType || "",
			region: museum?.region || "",
			interval: museum?.interval || null,
			wifiSSID: museum?.wifiSSID || null,
			wifiType: museum?.wifiType || null,
			wifiPassword: museum?.wifiPassword || "",
		},
		validationSchema: Yup.object().shape({
			name: Yup.string().required("Name is required"),
			address: Yup.string().required("Address is required"),
			ipAddress: Yup.string().matches(
				/(^(\d{1,3}\.){3}(\d{1,3})$)/,
				"Must be a valid ip address"
			),
			contactInfo: Yup.string(),
			beaconType: Yup.string().required("Beacon type is required")
		}),
		enableReinitialize: true,
		onSubmit: async (values, { resetForm }) => {
			if (museum === undefined || Object.keys(museum).length === 0) {

				const isValidSmallLogo = smallLogo !== null;
				const isValidLargelLogo = largeLogo !== null;

				setSmallLogoError(!isValidSmallLogo);
				setLargeLogoError(!isValidLargelLogo);

				if (isValidSmallLogo && isValidLargelLogo) {
					setLoading(true);
					try {
						const small_logo_filename = await getSignedUrl(smallLogo);
						const large_logo_filename = await getSignedUrl(largeLogo);

						if (small_logo_filename) {
							if (large_logo_filename) {
								const data = { ...values, small_logo: small_logo_filename, large_logo: large_logo_filename };
								addMuseum(data).then(() => {
									setLoading(false);
									onClose();
									resetForm();
									setSmallLogo(false);
									setLargeLogo(false);
									setSmallLogo(null);
									setLargeLogo(null);
									setNotification({
										isVisible: true,
										heading: 'Success',
										text: `${values.name} has been added successfully`
									});
									setTimeout(() => {
										setNotification({ isVisible: false, heading: '', text: '' })
									}, 10000);
								});
							}
						};
					} catch (err) {
						setLoading(false)
					}
				}
			} else {
				if ((museum?.small_logo || smallLogo) && (museum?.large_logo || largeLogo)) {
					let data: any = { ...values }
					setLoading(true);
					try {
						const updatedMuseum = museums?.data.filter((msm: any) => msm.id === museum.id)[0]
						const smallLogoFileName = getFileNameFromUrl(updatedMuseum.small_logo);
						const largeLogoFileName = getFileNameFromUrl(updatedMuseum.large_logo);
						if (smallLogo) {
							http.deleteS3Image(smallLogoFileName);
							const small_logo_filename = await getSignedUrl(smallLogo);
							data = { ...data, small_logo: small_logo_filename }
						}
						if (largeLogo) {
							http.deleteS3Image(largeLogoFileName);
							const large_logo_filename = await getSignedUrl(largeLogo);
							data = { ...data, large_logo: large_logo_filename }
						}

						updateMuseum(museum.id, {...data, wifiType: values.wifiType ? values.wifiType : null}).then(() => {
							setLoading(false);
							onClose();
							resetForm();
							setSmallLogo(false);
							setLargeLogo(false);
							setSmallLogo(null);
							setLargeLogo(null);
							setNotification({
								isVisible: true,
								heading: 'Success',
								text: `${museum.name} has been updated successfully`
							});
							setTimeout(() => {
								setNotification({ isVisible: false, heading: '', text: '' })
							}, 10000);
							setMuseum({});
						});
					} catch (err) {
						setLoading(false)
					}
				}
			}
		},
	});

	return (
		<Modal onClose={onClose} closeOnOverlayClick={false} size='4xl' isOpen={modal}>
			<ModalOverlay />
			<ModalContent>
				<form onSubmit={formik.handleSubmit}>
					<ModalHeader>{(museum === undefined || Object.keys(museum).length === 0) ? "Add " : "Update "} Museum</ModalHeader>
					<ModalCloseButton onClick={() => formik.resetForm()} />
					<ModalBody>
						<SimpleGrid columns={{ sm: 1, md: 2 }} spacing={{ base: '20px', xl: '20px' }}>
							<div>
								<InputField
									style={(formik.touched.name && formik.errors.name) && { border: '1.5px solid red' }} mb='0px' me='30px'
									{...formik.getFieldProps('name')}
									id='name'
									label='Museum Name'
									placeholder='Museum Name'
								/>
								{formik.touched.name && formik.errors.name ? (
									<div className="error-message">{formik.errors.name}</div>
								) : null}
							</div>
							<div>
								<InputField
									style={(formik.touched.address && formik.errors.address) && { border: '1.5px solid red' }} mb='0px'
									{...formik.getFieldProps('address')}
									id='address'
									label='Address'
									placeholder='Address'
								/>
								{formik.touched.address && formik.errors.address ? (
									<div className="error-message">{formik.errors.address}</div>
								) : null}
							</div>
							{/* <div>
								<InputField
									style={(formik.touched.interval && formik.errors.interval) && { border: '1.5px solid red' }} mb='0px' me='30px'
									{...formik.getFieldProps('interval')}
									id='name'
									label='Beacon Interval'
									placeholder='0.0'
									type='number'
								/>
								{formik.touched.interval && formik.errors.interval ? (
									<div className="error-message">{formik.errors.interval}</div>
								) : null}
							</div> */}
							<div>
								<InputField
									style={(formik.touched.ipAddress && formik.errors.ipAddress) && { border: '1.5px solid red' }}
									{...formik.getFieldProps('ipAddress')}
									mb='20px'
									me='30px'
									id='ipAddress'
									label='IP Address'
									placeholder='IP Address'
								/>
								{formik.touched.ipAddress && formik.errors.ipAddress ? (
									<div className="error-message">{formik.errors.ipAddress}</div>
								) : null}
							</div>
							<div>
								<label htmlFor='type' style={{ marginInlineEnd: '0.75rem', display: 'flex', textAlign: 'start', fontSize: '0.875rem', fontWeight: '700', marginInlineStart: '10px', marginBottom: '0.5rem' }}>Beacon Type</label>
								<Select
									placeholder='Select Beacon Type'
									style={(formik.touched.beaconType && formik.errors.beaconType) ? { border: '1.5px solid red', borderRadius: "1rem", height: "2.8rem" } : { borderRadius: "1rem", height: "2.8rem" }} mb='0px' me='30px'
									{...formik.getFieldProps('beaconType')}
									id='type'
								>
									{lookups?.beaconTypes?.map((item: any, key: any) => (
										<option key={key} value={item}>{item}</option>
									))}
								</Select>
								{formik.touched.beaconType && formik.errors.beaconType ? (
									<div className="error-message">{formik.errors.beaconType}</div>
								) : null}
							</div>
							{formik.values.beaconType === "BLE" && (
								<div>
									<InputField
										style={(formik.touched.region && formik.errors.region) && { border: '1.5px solid red' }}
										{...formik.getFieldProps('region')}
										mb='20px'
										me='30px'
										id='region'
										label='Region'
										placeholder='Region'
									/>
									{formik.touched.region && formik.errors.region ? (
										<div className="error-message">{formik.errors.region}</div>
									) : null}
								</div>
							)}
							<InputField
								style={(formik.touched.contactInfo && formik.errors.contactInfo) && { border: '1.5px solid red' }}
								{...formik.getFieldProps('contactInfo')}
								mb='20px'
								id='contactInfo'
								label='Contact Info'
								placeholder='Contact Info'
							/>
							<InputField
								style={(formik.touched.heartbeatPort && formik.errors.heartbeatPort) && { border: '1.5px solid red' }}
								{...formik.getFieldProps('heartbeatPort')}
								mb='20px'
								id='heartbeatPort'
								label='Heartbeat Port'
								placeholder='Heartbeat Port'
							/>
							<div>
								<label htmlFor='type' style={{ marginInlineEnd: '0.75rem', display: 'flex', textAlign: 'start', fontSize: '0.875rem', fontWeight: '700', marginInlineStart: '10px', marginBottom: '0.5rem' }}>WIFI Type</label>
								<Select
									placeholder='Select Wifi Type'
									style={(formik.touched.wifiType && formik.errors.wifiType) && { border: '1.5px solid red' }} mb='0px' me='30px'
									{...formik.getFieldProps('wifiType')}
									id='wifiType'
								>
									{lookups?.wifiSSIDTypes?.map((item: any, key: any) => (
										<option key={key} value={item}>{item}</option>
									))}
								</Select>
								{formik.touched.wifiType && formik.errors.wifiType ? (
									<div className="error-message">{formik.errors.wifiType}</div>
								) : null}
							</div>
							<InputField
								style={(formik.touched.wifiSSID && formik.errors.wifiSSID) && { border: '1.5px solid red' }}
								{...formik.getFieldProps('wifiSSID')}
								mb='20px'
								id='wifiSSID'
								label='WIFI SSID'
								placeholder='WIFI SSID'
							/>
							<InputField
								style={(formik.touched.wifiPassword && formik.errors.wifiPassword) && { border: '1.5px solid red' }}
								{...formik.getFieldProps('wifiPassword')}
								mb='20px'
								id='wifiPassword'
								label='WIFI Password'
								placeholder='WIFI Password'
							/>
						</SimpleGrid>
						<SimpleGrid columns={{ sm: 1, md: 3 }} spacing={{ base: '20px', xl: '20px' }} style={{ marginBottom: '1.2rem' }}>
							<div>
								<label htmlFor='isLiveBroadcost' style={{ marginInlineEnd: '0.75rem', display: 'flex', textAlign: 'start', fontSize: '0.875rem', fontWeight: '700', marginInlineStart: '10px', marginBottom: '0.5rem' }}>Behaviour Support</label>
								<Switch isChecked={formik.values.behaviourSupport} onChange={(e) => { formik.setFieldValue("behaviourSupport", e.target.checked) }} size='md' style={{ marginTop: '8px', marginLeft: '8px' }} />
							</div>
							<div>
								<label htmlFor='isLiveBroadcost' style={{ marginInlineEnd: '0.75rem', display: 'flex', textAlign: 'start', fontSize: '0.875rem', fontWeight: '700', marginInlineStart: '10px', marginBottom: '0.5rem' }}>Audio Descriptor</label>
								<Switch isChecked={formik.values.audioDescriptor} onChange={(e) => { formik.setFieldValue("audioDescriptor", e.target.checked) }} size='md' style={{ marginTop: '8px', marginLeft: '8px' }} />
							</div>
							<div>
								<label htmlFor='isLiveBroadcost' style={{ marginInlineEnd: '0.75rem', display: 'flex', textAlign: 'start', fontSize: '0.875rem', fontWeight: '700', marginInlineStart: '10px', marginBottom: '0.5rem' }}>Assistive Listening</label>
								<Switch isChecked={formik.values.assistiveListening} onChange={(e) => { formik.setFieldValue("assistiveListening", e.target.checked) }} size='md' style={{ marginTop: '8px', marginLeft: '8px' }} />
							</div>
						</SimpleGrid>
						<SimpleGrid columns={{ sm: 1, md: 2 }} spacing={{ base: '20px', xl: '20px' }}>
							<div>
								<FormLabel
									display='flex'
									ms='10px'
									htmlFor="small_logo"
									fontSize='sm'
									color={textColorPrimary}
									fontWeight='bold'
									_hover={{ cursor: 'pointer' }}>
									Small Logo
								</FormLabel>
								<Dropzone
									accept="image/*"
									fileChangeHandler={(files: any) => setSmallLogo(files[0])}
									isError={smallLogoError || ((!(smallLogo || museum?.small_logo) && formik.submitCount > 0))}
									isUpdateImage={museum?.small_logo || false}
									setIsUpdateImage={() => { setMuseum({ ...museum, small_logo: "" }); setSmallLogo(null) }}
									onFileSelect={() => setSmallLogoError(false)}
									content={
										<Box maxW='100%'>
											<Icon as={MdOutlineCloudUpload} w='80px' h='80px' color={textColor} />
											<Text
												mb='12px'
												fontSize='lg'
												w='100%'
												maxW='100%'
												fontWeight='700'
												color={textColor}
												whiteSpace='pre-wrap'>
												Drop your images here, or{' '}
												<Text as='span' fontSize='lg' fontWeight='700' color={brand}>
													click to browse
												</Text>
											</Text>
											<Text
												fontSize='sm'
												fontWeight='500'
												color='secondaryGray.500'
												white-space='pre-wrap !important'>
												PNG, JPG and GIF files are allowed
											</Text>
										</Box>
									}
								/>
								{(!(smallLogo || museum?.small_logo) && formik.submitCount > 0) ? (
									<div className="error-message">Small logo is required</div>
								) : null}
							</div>
							<div>
								<FormLabel
									display='flex'
									ms='10px'
									htmlFor="small_logo"
									fontSize='sm'
									color={textColorPrimary}
									fontWeight='bold'
									_hover={{ cursor: 'pointer' }}>
									Large Logo
								</FormLabel>
								<Dropzone
									accept="image/*"
									fileChangeHandler={(files: any) => setLargeLogo(files[0])}
									isError={largeLogoError || ((!(largeLogo || museum?.large_logo) && formik.submitCount > 0))}
									isUpdateImage={museum?.large_logo || false}
									setIsUpdateImage={() => setMuseum({ ...museum, large_logo: "" })}
									onFileSelect={() => setLargeLogoError(false)}
									content={
										<Box maxW='100%'>
											<Icon as={MdOutlineCloudUpload} w='80px' h='80px' color={textColor} />
											<Text
												mb='12px'
												fontSize='lg'
												w='100%'
												maxW='100%'
												fontWeight='700'
												color={textColor}
												whiteSpace='pre-wrap'>
												Drop your images here, or{' '}
												<Text as='span' fontSize='lg' fontWeight='700' color={brand}>
													click to browse
												</Text>
											</Text>
											<Text
												fontSize='sm'
												fontWeight='500'
												color='secondaryGray.500'
												white-space='pre-wrap !important'>
												PNG, JPG and GIF files are allowed
											</Text>
										</Box>
									}
								/>
								{(!(largeLogo || museum?.large_logo) && formik.submitCount > 0) ? (
									<div className="error-message">Large logo is required</div>
								) : null}
							</div>
						</SimpleGrid>
					</ModalBody>
					<ModalFooter>
						<Button onClick={() => { onClose(); formik.resetForm(); }}>Cancel</Button>
						<Button ml={2} isLoading={loading} loadingText='Loading...' colorScheme='blue' type='submit'>Save</Button>
					</ModalFooter>
				</form>
			</ModalContent>
		</Modal>
	);
}

const mapDispatchToProps = {
	addMuseum,
	updateMuseum
}

export default connect(null, mapDispatchToProps)(MuseumForm);