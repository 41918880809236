import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, useColorModeValue, FormLabel, Select, Switch, useToast } from '@chakra-ui/react';
import InputField from 'components/fields/InputField';
import { BeaconFormPropTypes } from 'types/propTypes';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import { addBeacon, updateBeacon } from '../../../../store/Actions/BeaconActions'
import BeaconValidation from 'validations/beaconValidation';
import { useContext } from 'react';
import { RootContext } from 'contextApi';
import http from 'services';
import { getExhibitList } from 'store/Actions/ExhibitActions';

function BeaconForm(props: BeaconFormPropTypes) {
	const { beacons, setNotification, exhibits, lookups, loading, setLoading, modal, onClose, beacon, addBeacon, updateBeacon } = props;
	const { museum } = useContext(RootContext);
	const toast = useToast();

	const formik = useFormik({
			initialValues: {
				uniqueId: beacon?.uniqueId || "",
				exhibitId: beacon?.exhibitId || "",
				light: beacon?.light || "",
				sound: beacon?.sound || "",
				range: beacon?.range || null,
				isLiveBroadcost: beacon?.isLiveBroadcost || false,
				berixAudioPointIpWithPort: beacon?.berixAudioPointIpWithPort,
				portToSendLocForAmxControl: beacon?.portToSendLocForAmxControl,
				ratio: beacon?.ratio || null,
				txPower: beacon?.txPower || null,
			},
			validationSchema: BeaconValidation,
			enableReinitialize: true,
			onSubmit: async (values, { resetForm, setErrors }) => {
				if(!museum) {
					toast({
						title: `Please select a museum to modify its beacon!`,
						position: 'top',
						status: 'warning',
						isClosable: true
					});
					return false;
				}

				// @ts-ignore
				const newValues = { ...values, exhibitId: parseInt(values.exhibitId) }
				let beaconIdExist = false;
				setLoading(true);
				await http.getAllBeacons().then( async res => {
					beaconIdExist = res.data.data.find((item: any) => ((item.uniqueId === newValues.uniqueId && item.id !== beacon?.id)));
						if(beaconIdExist) {
							setErrors({ uniqueId: "Beacon ID Already Exists" });
							setLoading(false);
							return false;
						}

						setLoading(true);
						try {
							if(beacon === undefined || Object.keys(beacon).length === 0) {
								console.log("EXHIBITS => ", exhibits);
								const exhibit = exhibits.data.filter((item: any) => item.id === newValues.exhibitId)[0];
								await addBeacon({...newValues, exhibit: exhibit.exhibitName});
							} else {
								await updateBeacon(beacon.id, newValues);
							}
							setLoading(false);
							resetForm();
							onClose();
							setNotification({
								isVisible: true,
								heading: 'Success',
								text: `Beacon has been ${(beacon === undefined || Object.keys(beacon).length === 0) ? "Saved " : "Updated "} successfully`
							});
							setTimeout(() => {
								setNotification({isVisible: false, heading: '', text: ''})
							}, 10000);
						
						} catch (err) {
							console.log("ress => ", err);
							// @ts-ignore
							console.log("ress => ", err.response.data);
							// @ts-ignore
							console.log("ress => ", err.response);
						}
				
				})
			}
	});

	return (
		<Modal onClose={onClose} size='4xl' isOpen={modal} closeOnOverlayClick={false}>
			<ModalOverlay />
			<ModalContent>
				<form onSubmit={formik.handleSubmit}>
					<ModalHeader>{(beacon === undefined || Object.keys(beacon).length === 0) ? "Add " : "Update "} Beacon</ModalHeader>
					<ModalCloseButton onClick={() => formik.resetForm()} />
					<ModalBody>
							<SimpleGrid columns={{ sm: 1, md: 2 }} spacing={{ base: '20px', xl: '20px' }}>
								<div>
									<InputField 
										style={(formik.touched.uniqueId && formik.errors.uniqueId) && { border: '1.5px solid red' }} mb='0px' me='30px' 
										{...formik.getFieldProps('uniqueId')} 
										id='uniqueId' 
										label='Unique ID' 
										placeholder='Unique ID'
									/>
									{formik.touched.uniqueId && formik.errors.uniqueId ? (
										<div className="error-message">{formik.errors.uniqueId}</div>
									) : null}
								</div>
								<div>
									<label htmlFor='exhibitId' style={{ marginInlineEnd: '0.75rem', display: 'flex', textAlign: 'start', fontSize: '0.875rem', fontWeight: '700', marginInlineStart: '10px', marginBottom: '0.5rem' }}>Select Exhibit</label>
									<Select 
										placeholder='Select Exhibit'
										style={(formik.touched.exhibitId && formik.errors.exhibitId) && { border: '1.5px solid red' }} mb='0px' me='30px' 
										{...formik.getFieldProps('exhibitId')} 
										id='exhibitId'  
									>
										{exhibits?.data?.map((item: any, key: any) => (
											<option key={key} value={item.id}>
												{item.exhibitName}
											</option>
										))}
									</Select>
									{formik.touched.exhibitId && formik.errors.exhibitId ? (
										<div className="error-message">{formik.errors.exhibitId}</div>
									) : null}
								</div>
								<div>
									<label htmlFor='sound' style={{ marginInlineEnd: '0.75rem', display: 'flex', textAlign: 'start', fontSize: '0.875rem', fontWeight: '700', marginInlineStart: '10px', marginBottom: '0.5rem' }}>Sound</label>
									<Select 
										placeholder='Select Surrounding Sound'
										style={(formik.touched.sound && formik.errors.sound) && { border: '1.5px solid red' }} mb='0px' me='30px' 
										{...formik.getFieldProps('sound')} 
										id='sound'  
									>
										{lookups?.beaconSoundEnvirenment?.map((item: any, key: any) => (
											<option key={key} value={item}>
												{item === "LOW" && "Quiet"}
												{item === "MEDIUM" && "Typical"}
												{item === "HIGH" && "Loud"}
											</option>
										))}
									</Select>
									{formik.touched.sound && formik.errors.sound ? (
										<div className="error-message">{formik.errors.sound}</div>
									) : null}
								</div>
								<div>
									<label htmlFor='light' style={{ marginInlineEnd: '0.75rem', display: 'flex', textAlign: 'start', fontSize: '0.875rem', fontWeight: '700', marginInlineStart: '10px', marginBottom: '0.5rem' }}>Light</label>
									<Select 
										placeholder='Select Surrounding Light'
										style={(formik.touched.light && formik.errors.light) && { border: '1.5px solid red' }} mb='0px' me='30px' 
										{...formik.getFieldProps('light')} 
										id='light'  
									>
										{lookups?.beaconLightEnvirenment?.map((item: any, key: any) => (
											<option key={key} value={item}>
												{item === "LOW" && "Calming (green)"}
												{item === "MEDIUM" && "Typical (yellow)"}
												{item === "HIGH-DARK" && "Dark (red)"}
												{item === "HIGH-BRIGHT" && "Bright (red)"}
												{item === "HIGH-FLASHING" && "Flashing (red)"}
											</option>
										))}
									</Select>
									{formik.touched.light && formik.errors.light ? (
										<div className="error-message">{formik.errors.light}</div>
									) : null}
								</div>
								{/* <div>
									<InputField 
										style={(formik.touched.ratio && formik.errors.ratio) && { border: '1.5px solid red' }} mb='0px' me='30px' 
										{...formik.getFieldProps('ratio')} 
										id='name' 
										label='Beacon Ratio' 
										placeholder='0.0' 
										type='number'
									/>
									{formik.touched.ratio && formik.errors.ratio ? (
										<div className="error-message">{formik.errors.ratio}</div>
									) : null}
								</div> */}
								<div>
									<InputField 
										style={(formik.touched.txPower && formik.errors.txPower) && { border: '1.5px solid red' }} mb='0px' me='30px' 
										{...formik.getFieldProps('txPower')} 
										id='name' 
										label='Beacon txPower' 
										placeholder='0.0' 
										type='number'
									/>
									{formik.touched.txPower && formik.errors.txPower ? (
										<div className="error-message">{formik.errors.txPower}</div>
									) : null}
								</div>
								<div>
									<InputField 
										style={(formik.touched.berixAudioPointIpWithPort && formik.errors.berixAudioPointIpWithPort) && { border: '1.5px solid red' }} mb='0px' me='30px' 
										{...formik.getFieldProps('berixAudioPointIpWithPort')} 
										id='name' 
										label='Berix Audio Point IP : Port' 
										placeholder='192.168.18.95:9444' 
									/>
									{formik.touched.berixAudioPointIpWithPort && formik.errors.berixAudioPointIpWithPort ? (
										<div className="error-message">{formik.errors.berixAudioPointIpWithPort}</div>
									) : null}
								</div>
								<div>
										<label htmlFor='isLiveBroadcost' style={{ marginInlineEnd: '0.75rem', display: 'flex', textAlign: 'start', fontSize: '0.875rem', fontWeight: '700', marginInlineStart: '10px', marginBottom: '0.5rem' }}>Live Broadcost</label>
										<Switch isChecked={formik.values.isLiveBroadcost} onChange={(e) => { formik.setFieldValue("isLiveBroadcost", e.target.checked) }} size='md' style={{ marginTop: '8px', marginLeft: '8px' }} />
								</div>
								<div>
									<InputField 
										style={(formik.touched.range && formik.errors.range) && { border: '1.5px solid red' }} mb='0px' me='30px' 
										{...formik.getFieldProps('range')} 
										id='range' 
										type='number'
										label='Beacon Range' 
										placeholder='1.1' 
									/>
									{formik.touched.range && formik.errors.range ? (
										<div className="error-message">{formik.errors.range}</div>
									) : null}
								</div>
								<div>
									<InputField 
										style={(formik.touched.portToSendLocForAmxControl && formik.errors.portToSendLocForAmxControl) && { border: '1.5px solid red' }} mb='0px' me='30px' 
										{...formik.getFieldProps('portToSendLocForAmxControl')} 
										id='name' 
										label='LOC AMX Port' 
										placeholder='LOC AMX Port' 
									/>
									{formik.touched.portToSendLocForAmxControl && formik.errors.portToSendLocForAmxControl ? (
										<div className="error-message">{formik.errors.portToSendLocForAmxControl}</div>
									) : null}
								</div>
							</SimpleGrid>
					</ModalBody>
					<ModalFooter>
						<Button onClick={() => { onClose(); formik.resetForm() }}>Cancel</Button>
						<Button ml={2} isLoading={loading} loadingText='Loading...' colorScheme='blue' type='submit'>Save</Button>
					</ModalFooter>
				</form>
			</ModalContent>
		</Modal>
	);
}

const mapDispatchToProps = {
  addBeacon,
	updateBeacon,
	getExhibitList
}

export default connect(null, mapDispatchToProps)(BeaconForm);