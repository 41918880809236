// Chakra imports
import { Flex } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import http from 'services';
import { supportingMediaColumns } from './variables/supportingMediaColumns';
import Notification from 'components/notification/Notification';
import { getFileNameFromUrl } from 'helpers/functions';
import { getAssistancesList } from 'store/Actions/AssistanceActions';
import { getLookupsList } from 'store/Actions/LookupActions';
import { deleteSupportingMedia, getSupportingMediaList } from 'store/Actions/SupportingMediaActions';
import SupportingMediasTable from './components/SupportingMediaTable';
import SupportingMediaForm from '../form'
import MediaModal from 'components/MediaModal';

// @ts-ignore
const SupportingMediaList = ({ lookups, getLookupsList, getAssistancesList, supportingMedias, assistances, deleteSupportingMedia, getSupportingMediaList }) => {

	const [modal, setModal] = useState(false);
	const [supportingMedia, setSupportingMedia] = useState({});
	const [loading, setLoading] = useState(false);
	const [notification, setNotification] = useState({ isVisible: false, heading: "", text: "" });
	const [loadingData, setLoadingData] = useState(false);
	const [mediaModal, setMediaModal] = useState(false);
	const [media, setMedia] = useState(null);

	useEffect(() => {
		setLoadingData(true);
		Promise.all([ supportingMedias?.data.length === 0 && getSupportingMediaList() , assistances?.data.length === 0 && getAssistancesList()]).then(res => {
			setLoadingData(false);
		}).finally(() => {
			setLoadingData(false);
		});
		if(lookups && Object.keys(lookups).length === 0) {
			getLookupsList();
		} 
	}, [assistances?.data.length, getAssistancesList, getLookupsList, getSupportingMediaList, lookups, supportingMedias?.data.length]);

	const data:any = [];
	if(assistances?.data.length > 0) {
		supportingMedias?.data.forEach((item: any) => {
			const tempItem = JSON.parse(JSON.stringify(item));
			tempItem.assistance = assistances?.data?.filter((assistanc: any) => assistanc.id === item.assistanceId)[0]?.id + " - " + getFileNameFromUrl(assistances?.data?.filter((assistanc: any) => assistanc.id === item.assistanceId)[0]?.mediaUrl);
			tempItem.actions = item.id;
			data.push(tempItem);
		})
	}

	const editSupportingMediaHandler = (id: number) => {
		setSupportingMedia(supportingMedias?.data.filter((item: any) => item.id === id)[0]);
	}

	const deleteSupportingMediaHandler = (id: number) => {
		try {
			const supportingMed = supportingMedias?.data.filter((item: any) => item.id === id)[0]
			setSupportingMedia(supportingMed);
			setLoading(true);
			deleteSupportingMedia(id).then(() => {
				const supportingMediaUrl = getFileNameFromUrl(supportingMed.url);
				http.deleteS3Image(supportingMediaUrl).then(res => {
					setLoading(false);
					setNotification({
						isVisible: true,
						heading: 'Success',
						text: `Supporting Media has been deleted successfully`
					});
					setTimeout(() => {
						setNotification({isVisible: false, heading: '', text: ''})
					}, 10000);
				});
			});
		} catch(err) {
			setLoading(false);
		}
	}

	console.log("data => ", data);

	return (
		<Flex direction='column' pt={{ sm: '125px', lg: '75px' }}>
			<Card px='0px'>
				<Notification 
					isVisible={notification.isVisible}
					heading={notification.heading}
					text={notification.text}
				/>
				<SupportingMediasTable 
					tableData={data} 
					columnsData={supportingMediaColumns} 
					onOpen={() => setModal(true)}
					setSupportingMedia={editSupportingMediaHandler}
					deleteSupportingMediaHandler={deleteSupportingMediaHandler}
					loading={loading}
					supportingMedia={supportingMedia}
					loadingData={loadingData}
					setMedia={(media: any) => { setMedia(media); setMediaModal(true)}}
				/>
			</Card>
			<SupportingMediaForm
				modal={modal}
				onClose={() => setModal(false)} supportingMedia={supportingMedia} setSupportingMedia={setSupportingMedia}
				loading={loading}
				setLoading={setLoading}
				setNotification={setNotification}
				supportingMedias={supportingMedias}
				assistances={assistances} 	
			/>
			<MediaModal 
				onClose={() => {setMediaModal(false); setMedia(null)}} 
				media={media}
				modal={mediaModal}
			/>
		</Flex>
	);
}

const mapStateToProps = (state: any) => ({
  supportingMedias: state.supportingMedias,
	assistances: state.assistances,
})

const mapDispatchToProps = {
  getSupportingMediaList,
	deleteSupportingMedia,
	getAssistancesList,
	getLookupsList
}

export default connect(mapStateToProps, mapDispatchToProps)(SupportingMediaList);