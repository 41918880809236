import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import { getFileNameFromUrl, isVideo } from 'helpers/functions'

function MediaModal({ onClose, modal, media }: { onClose: () => void, modal: boolean, media: string }) {
  
  if(media) {
    console.log("MEDIA MODAL => ", media);
    console.log("MEDIA MODAL filename => ", getFileNameFromUrl(media));
    console.log("MEDIA MODAL isVideo => ", isVideo(getFileNameFromUrl(media)));
  }
  
  return (
    <Modal onClose={onClose} size='4xl' isOpen={modal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Assistance Media</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {media && isVideo(getFileNameFromUrl(media)) ? (
            <video width="1000" height="700" controls>
              <source src={media} type="video/mp4" />
              <source src={media} type="video/ogg" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <figure>
              <audio
                  style={{ width: '100%' }}
                  controls
                  src={media}>
                       Your browser does not support the audio element.
              </audio>
            </figure>
          )}
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default MediaModal