// Chakra imports
import { Flex } from "@chakra-ui/react";
import Card from "components/card/Card";
import { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import http from "services";
import { getBeaconsList } from "store/Actions/BeaconActions";
import { assistanceColumns } from "./variables/assistanceColumns";
import Notification from "components/notification/Notification";
import { getFileNameFromUrl } from "helpers/functions";
import {
  deleteAssistance,
  getAssistancesList,
} from "store/Actions/AssistanceActions";
import { getLookupsList } from "store/Actions/LookupActions";
import AssistancesTable from "./components/AssistancesTable";
import AssistanceForm from "../form";
import MediaModal from "components/MediaModal";
import { getSupportingMediaList } from "store/Actions/SupportingMediaActions";
import { RootContext } from "contextApi";
import { useParams } from "react-router-dom";
import { getMuseumsList } from "store/Actions/MuseumActions";

// @ts-ignore
const AssistanceList = ({
  museums,
  getMuseumsList,
  getSupportingMediaList,
  lookups,
  getLookupsList,
  getAssistancesList,
  assistances,
  beacons,
  deleteAssistance,
  getBeaconsList,
}: any) => {
  const [modal, setModal] = useState(false);
  const [assistance, setAssistance] = useState({});
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({
    isVisible: false,
    heading: "",
    text: "",
  });
  const [loadingData, setLoadingData] = useState(false);
  const [mediaModal, setMediaModal] = useState(false);
  const [media, setMedia] = useState(null);
  const { museum } = useContext(RootContext);
  // @ts-ignore
  const { id } = useParams();

  useEffect(() => {
    setLoadingData(true);
    Promise.all([
      beacons?.data.length === 0 && getBeaconsList(museum),
      assistances?.data.length === 0 && getAssistancesList(id),
      getMuseumsList(),
    ])
      .then((res) => {
        setLoadingData(false);
      })
      .finally(() => {
        setLoadingData(false);
      });
    if (lookups && Object.keys(lookups).length === 0) {
      getLookupsList();
    }
  }, [
    assistances?.data.length,
    beacons?.data.length,
    getAssistancesList,
    getBeaconsList,
    getLookupsList,
    getMuseumsList,
    id,
    lookups,
    museum,
    museums?.data.length,
  ]);

  const data: any = [];
  assistances?.data.forEach((item: any) => {
    const tempItem = JSON.parse(JSON.stringify(item));
    tempItem.beacon = beacons?.data?.filter(
      (beacon: any) => beacon.id === item.beaconId
    )[0]?.exhibitName;
    tempItem.actions = item.id;
    data.push(tempItem);
  });

  const editAssistanceHandler = (id: number) => {
    setAssistance(assistances?.data.filter((item: any) => item.id === id)[0]);
  };

  const deleteAssistanceHandler = (id: number) => {
    try {
      const assistanc = assistances?.data.filter(
        (item: any) => item.id === id
      )[0];
      setAssistance(assistanc);
      setLoading(true);
      deleteAssistance(id).then(() => {
        setNotification({
          isVisible: true,
          heading: "Success",
          text: `${
            assistanc.type + " : " + getFileNameFromUrl(assistanc.mediaUrl)
          } has been deleted successfully`,
        });
        setTimeout(() => {
          setNotification({ isVisible: false, heading: "", text: "" });
        }, 10000);
      });
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <Flex direction="column" pt={{ sm: "125px", lg: "75px" }}>
      <Card px="0px">
        <Notification
          isVisible={notification.isVisible}
          heading={notification.heading}
          text={notification.text}
        />
        <AssistancesTable
          tableData={data}
          columnsData={assistanceColumns}
          onOpen={() => setModal(true)}
          setAssistance={editAssistanceHandler}
          deleteAssistanceHandler={deleteAssistanceHandler}
          loading={loading}
          assistance={assistance}
          museum={
            museums?.data.length > 0 &&
            museums?.data.filter((item: any) => item.id === parseInt(museum))[0]
          }
          loadingData={loadingData}
          setMedia={(media: any) => {
            setMedia(media);
            setMediaModal(true);
          }}
          selectedBeacon={
            beacons.data.filter(
              (beacon: any) => parseInt(beacon.id) === parseInt(id)
            )[0]
          }
        />
      </Card>
      <AssistanceForm
        modal={modal}
        onClose={() => setModal(false)}
        assistance={assistance}
        setAssistance={setAssistance}
        loading={loading}
        setLoading={setLoading}
        setNotification={setNotification}
        museum={
          museums?.data.length > 0 &&
          museums?.data.filter((item: any) => item.id === parseInt(museum))[0]
        }
        beacons={beacons}
        lookups={lookups}
        assistances={assistances}
        selectedBeacon={beacons?.data?.filter(
          (beacon: any) => parseInt(beacon.id) === parseInt(id)
        )[0]}
      />
      <MediaModal
        onClose={() => {
          setMediaModal(false);
          setMedia(null);
        }}
        media={media}
        modal={mediaModal}
      />
    </Flex>
  );
};

const mapStateToProps = (state: any) => ({
  beacons: state.beacons,
  assistances: state.assistances,
  lookups: state.lookups,
  museums: state.museums,
});

const mapDispatchToProps = {
  getAssistancesList,
  deleteAssistance,
  getBeaconsList,
  getLookupsList,
  getSupportingMediaList,
  getMuseumsList,
};

export default connect(mapStateToProps, mapDispatchToProps)(AssistanceList);
