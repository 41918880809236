export const supportingMediaColumns = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Media",
    accessor: "url",
  },
  {
    Header: "Assistance",
    accessor: "assistance",
  },
  {
    Header: "Actions",
    accessor: "actions",
  },
];
