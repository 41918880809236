import {
  Button,
  Image,
  Box,
  Flex,
  Icon,
  SimpleGrid,
  Text,
  useColorModeValue,
  Progress,
  useToast,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import Dropzone, { IFile } from "./components/Dropzone";
import { MdOutlineCloudUpload } from "react-icons/md";
import Card from "components/card/Card";
import { IoTrashBin, IoMusicalNotes } from "react-icons/io5";
import { useEffect, useState } from "react";
import {
  calculateFileSize,
  getFileNameFromUrl,
  isAudioFormatSupported,
} from "helpers/functions";
import http from "services";
import AUDIO_ICON from "../../../assets/img/audio-img.jpg";
import MediaModal from "components/MediaModal";

// @ts-ignore
const MediaLibrary = () => {
  const toast = useToast();
  const [files, setFiles] = useState<any[]>([]);
  const [fileList, setFileList] = useState<IFile[]>([]);
  const [key, setKey] = useState(1);
  const [audioFiles, setAudioFiles] = useState([]);
  const [videoFiles, setVideoFiles] = useState([]);
  const [allMediaFiles, setAllMediaFiles] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [media, setMedia] = useState(null);
  const [deletedSupportingMedia, setDeletedSupportingMedia] = useState(null);
  const [mediaModal, setMediaModal] = useState(false);
  const [mediaUploading, setMediaUploading] = useState(false);

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const brand = useColorModeValue("brand.500", "brand.400");

  useEffect(() => {
    getMediaLibrary();
  }, []);

  const getMediaLibrary = () => {
    http.getMediaLibraries().then((res) => {
      const allFiles = res.data.data;
      const audioFiles = allFiles.filter(
        (file: any) => file.name.endsWith(".mp3") || file.name.endsWith(".aif")
      );
      const videoFiles = allFiles.filter((file: any) =>
        file.name.endsWith(".mp4")
      );
      setAudioFiles(audioFiles);
      setVideoFiles(videoFiles);
      setAllMediaFiles(allFiles);
    });
  };

  const onFileUpload = (files: any) => {
    toast({
      title: `Files uploaded successfully, now saving them to the database...`,
      position: "top",
      status: "success",
      isClosable: true,
    });
    const fileNames = files.map((file: any) => file.name);
    http
      .createMediaLibrary({ name: fileNames })
      .then((res) => {
        toast({
          title: `Files saved successfully`,
          position: "top",
          status: "success",
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: "Unable to upload files",
          position: "top",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setFiles([]);
        setFileList([]);
        setKey(Math.random() * 10000);
        setMediaUploading(false);
        getMediaLibrary();
      });
  };

  const removeFile = (media: any) => {
    setDeleteLoading(true);
    setDeletedSupportingMedia(media);
    http
      .deleteMediaLibrary(media.id)
      .then((res) => {
        toast({
          title: `File deleted successfully`,
          position: "top",
          status: "success",
          isClosable: true,
        });
        getMediaLibrary();
      })
      .finally(() => {
        setDeleteLoading(false);
      });
  };

  console.log("files => ", files);
  console.log("fileList => ", fileList);

  return (
    <Flex
      direction="column"
      pt={{ sm: "125px", lg: "75px" }}
      style={{ padding: "1rem", marginTop: "5rem" }}
    >
      <Card p="20px" key={key}>
        <Dropzone
          content={
            <Box maxW="100%">
              <Icon
                as={MdOutlineCloudUpload}
                w="80px"
                h="80px"
                color={textColor}
              />
              {mediaUploading ? (
                <Text
                  mb="12px"
                  fontSize="lg"
                  w="100%"
                  maxW="100%"
                  fontWeight="700"
                  color={textColor}
                  whiteSpace="pre-wrap"
                >
                  Uploading Media...
                </Text>
              ) : (
                <>
                  <Text
                    mb="12px"
                    fontSize="lg"
                    w="100%"
                    maxW="100%"
                    fontWeight="700"
                    color={textColor}
                    whiteSpace="pre-wrap"
                  >
                    Drop your images here, or{" "}
                    <Text as="span" fontSize="lg" fontWeight="700" color={brand}>
                      click to browse
                    </Text>
                  </Text>
                  <Text
                    fontSize="sm"
                    fontWeight="500"
                    color="secondaryGray.500"
                    white-space="pre-wrap !important"
                  >
                    PM3, MP4 files are allowed
                  </Text>
                </>
              )}
            </Box>
          }
          setFiles={setFiles}
          files={files}
          fileList={fileList}
          setFileList={setFileList}
          onFileUpload={onFileUpload}
          setMediaUploading={setMediaUploading}
          mediaUploading={mediaUploading}
        />

        <br />

        {files.map((file: any, index: number) => {
          let fileType = file.content_type && file.content_type.split("/")[0];
          return (
            <div
              className="supportingMediaBoxMediaLibrary"
              id="supportingMedias"
            >
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>
                  {fileType === "video" ? (
                    <>
                      {file?.fileObject && (
                        <video width="75" height="50">
                          <source
                            src={URL.createObjectURL(file?.fileObject)}
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                      )}
                    </>
                  ) : (
                    <Icon
                      transition="0.2s linear"
                      w="60px"
                      h="60px"
                      as={IoMusicalNotes}
                      color="brand.500"
                    />
                  )}
                </div>
              </p>
              <div
                style={{
                  flex: "2",
                  marginLeft: "15px",
                  marginRight: "15px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Progress value={file.progress} style={{ width: "100%" }} />
              </div>
              <div
                style={{
                  marginLeft: "15px",
                  marginRight: "15px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {calculateFileSize(file.progress, file.fileObject)}
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Button
                  alignItems="center"
                  justifyContent="center"
                  w="37px"
                  h="37px"
                  lineHeight="100%"
                  borderRadius="10px"
                  onClick={() => {
                    if (file.progress !== 100) {
                      file.abortController.cancel();
                      files.splice(index, 1);
                      setFileList(
                        fileList.filter((filee) => filee.name !== file.name)
                      );
                      setFiles([...files]);
                    }
                  }}
                >
                  x{" "}
                  {/* <Icon as={MdAddCircle} color={iconColor} w='24px' h='24px' /> */}
                </Button>
              </div>
            </div>
          );
        })}

        <br />

        <Tabs isFitted variant="enclosed">
          <TabList mb="1em">
            <Tab>Audio Files</Tab>
            <Tab>Video Files</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              {audioFiles.length === 0 && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>No Audio Files Uploaded</div>
              )}
              {audioFiles.map((media) => (
                <div className="supportingMediaBox" id="supportingMedias">
                  <p style={{ paddingTop: "8px", fontWeight: "200" }}>
                    {media.name}
                  </p>
                  <div>
                    <Button
                      colorScheme="teal"
                      variant="outline"
                      onClick={() => {
                        window.open(media.url, "_blank");
                      }}
                    >
                      Play Media
                    </Button>
                    {/* @ts-ignore */}
                    <Button
                      disabled={
                        media.id === deletedSupportingMedia?.id && deleteLoading
                      }
                      ml={2}
                      colorScheme="red"
                      variant="solid"
                      onClick={removeFile.bind(this, media)}
                    >
                      {media.id === deletedSupportingMedia?.id && deleteLoading
                        ? "Deleting..."
                        : "Delete"}
                    </Button>
                  </div>
                </div>
              ))}
            </TabPanel>
            <TabPanel>
              {videoFiles.length === 0 && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>No Video Files Uploaded</div>
              )}
              {videoFiles.map((media) => (
                <div className="supportingMediaBox" id="supportingMedias">
                  <p style={{ paddingTop: "8px", fontWeight: "200" }}>
                    <video width="75" height="50">
                      <source
                        src={media.url}
                        type="video/mp4"
                      />
                        Your browser does not support the video tag.
                    </video>
                  </p>
                  <p style={{ paddingTop: "8px", fontWeight: "200" }}>
                    {media.name}
                  </p>
                  <div>
                    <Button
                      colorScheme="teal"
                      variant="outline"
                      onClick={() => {
                        setMedia(media.url);
                        setMediaModal(true);
                      }}
                    >
                      Play Media
                    </Button>
                    {/* @ts-ignore */}
                    <Button
                      disabled={
                        media.id === deletedSupportingMedia?.id && deleteLoading
                      }
                      ml={2}
                      colorScheme="red"
                      variant="solid"
                      onClick={removeFile.bind(this, media)}
                    >
                      {media.id === deletedSupportingMedia?.id && deleteLoading
                        ? "Deleting..."
                        : "Delete"}
                    </Button>
                  </div>
                </div>
              ))}
            </TabPanel>
          </TabPanels>
        </Tabs>
        {/* <SimpleGrid
          columns={{ base: 1, md: 4 }}
          gap="20px"
          style={{ marginTop: "3rem" }}
        >
          {mediaFiles.map((mediaFile) => {
            console.log('mediaFile => ', mediaFile);
            return (
              <Box mb={{ base: "20px", "2xl": "20px" }} position="relative">
                {isAudioFormatSupported(mediaFile.name) ? (
                  <video style={{ borderRadius: '20px' }} width="400" height="200" controls>
                    <source
                      src={mediaFile.url}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <Image
                    src={AUDIO_ICON}
                    w={{ base: "100%", "3xl": "100%" }}
                    h={{ base: "100%", "3xl": "100%" }}
                    borderRadius="20px"
                    className="media-library-image"
                    onClick={() => {
                      window.open(mediaFile.url, "_blank")
                    }}
                  />
                )} 
                
                <Button
                  position="absolute"
                  bg="white"
                  _hover={{ bg: "whiteAlpha.900" }}
                  _active={{ bg: "white" }}
                  _focus={{ bg: "white" }}
                  p="0px !important"
                  top="14px"
                  right="14px"
                  borderRadius="50%"
                  minW="36px"
                  h="36px"
                  onClick={removeFile.bind(this, mediaFile.id)}
                >
                  <Icon
                    transition="0.2s linear"
                    w="20px"
                    h="20px"
                    as={IoTrashBin}
                    color="brand.500"
                  />
                </Button>
              </Box>
            )
          })}
        </SimpleGrid> */}
      </Card>
      <MediaModal
        onClose={() => {
          setMediaModal(false);
          setMedia(null);
        }}
        media={media}
        modal={mediaModal}
      />
    </Flex>
  );
};

export default MediaLibrary;
