import http from 'services';
import { ADD_EXHIBIT, DELETE_EXHIBIT, EDIT_EXHIBIT, GET_ALL_EXHIBIT } from './types'

export const getExhibitList = (museum: any) => async (dispatch: any) => {
	try {
		const response = await http.getExhibits(museum);
		
		if(response.data.data.length > 0) {
			dispatch({
				type: GET_ALL_EXHIBIT,
				payload: response.data.data
			})
		} else {
			dispatch({
				type: GET_ALL_EXHIBIT,
				payload: []
			})
		}


		return response;
	} catch (err) {
    console.log("ERROR => ", err);
	}
}

export const addExhibit = (data: any) => async (dispatch: any) => {
	try {
		const response = await http.createExhibit(data);
		
		dispatch({
			type: ADD_EXHIBIT,
			payload: data
		})
		return response;
	} catch (err) {
    console.log("ERROR => ", err);
	}
}

export const updateExhibit = (id: number, data: any) => async (dispatch: any) => {
	try {
		const response = await http.updateExhibit(id, data);
		
		dispatch({
			type: EDIT_EXHIBIT,
			payload: {
				id: id,
				data: response.data.data
			}
		})
		return response;
	} catch (err) {
    console.log("ERROR => ", err);
	}
}

export const deleteExhibit = (id: number) => async (dispatch: any) => {
	try {
		const response = await http.deleteExhibit(id);
		
		dispatch({
			type: DELETE_EXHIBIT,
			payload: id
		})
		return response;
	} catch (err) {
    console.log("ERROR => ", err);
	}
}