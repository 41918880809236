/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \ 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/ 
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useContext } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
// Chakra imports
import {
	Box,
	Button,
	Checkbox,
	Flex,
	FormControl,
	FormLabel,
	Heading,
	Icon,
	Input,
	InputGroup,
	InputRightElement,
	Text,
	useColorModeValue
} from '@chakra-ui/react';
// Custom components
import { HSeparator } from 'components/separator/Separator';
import DefaultAuth from 'layouts/auth/variants/Default';
// Assets
import illustration from 'assets/img/auth/auth.png';
import { FcGoogle } from 'react-icons/fc';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import { RootContext } from "contextApi";
import { ROUTES } from "../../../constants";
import Storage from "helpers/localstorage";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import http from 'services';
import instance from 'interceptors/axios';
import jwt from 'jwt-decode'
import LoginValidation from 'validations/loginvalidation';

function SignIn() {
	// Chakra color mode
	const textColor = useColorModeValue('navy.700', 'white');
	const textColorSecondary = 'gray.400';
	const textColorDetails = useColorModeValue('navy.700', 'secondaryGray.600');
	const textColorBrand = useColorModeValue('brand.500', 'white');
	const brandStars = useColorModeValue('brand.500', 'brand.400');
	const googleBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.200');
	const googleText = useColorModeValue('navy.700', 'white');
	const googleHover = useColorModeValue({ bg: 'gray.200' }, { bg: 'whiteAlpha.300' });
	const googleActive = useColorModeValue({ bg: 'secondaryGray.300' }, { bg: 'whiteAlpha.200' });
	const [ show, setShow ] = React.useState(false);
	const [loading, setLoading ] = React.useState(false);

	const { setUser } = useContext(RootContext);
  const history = useHistory();
	
	const handleClick = () => setShow(!show);
	
	const formik = useFormik({
			initialValues: {
				username: "",
				password: ""
			},
			validationSchema: LoginValidation,
			onSubmit: async values => {
				setLoading(true);
				http.login(values).then(async res => {
					instance.defaults.headers.common['authorization'] = "bearer " + res.data.data.access_token;
					const user = await jwt(res.data.data.access_token);
					Storage.set("user", JSON.stringify(user));
					Storage.set("access_token", res.data.data.access_token);
					setUser(user);
					setLoading(false);
					history.push(ROUTES.BEACONS)
				})
			}
	})
	
	return (
		<DefaultAuth illustrationBackground={illustration} image={illustration}>
			<form onSubmit={formik.handleSubmit}>
				<Flex
					maxW={{ base: '100%', md: 'max-content' }}
					w='100%'
					mx={{ base: 'auto', lg: '0px' }}
					me='auto'
					h='100%'
					alignItems='start'
					justifyContent='center'
					mb={{ base: '30px', md: '60px' }}
					px={{ base: '25px', md: '0px' }}
					mt={{ base: '40px', md: '14vh' }}
					flexDirection='column'>
					<Box me='auto'>
						<Heading color={textColor} fontSize='36px' mb='10px'>
							Sign In
						</Heading>
						<Text mb='36px' ms='4px' color={textColorSecondary} fontWeight='400' fontSize='md'>
							Enter your email and password to sign in!
						</Text>
					</Box>
					<Flex
						zIndex='2'
						direction='column'
						w={{ base: '100%', md: '420px' }}
						maxW='100%'
						background='transparent'
						borderRadius='15px'
						mx={{ base: 'auto', lg: 'unset' }}
						me='auto'
						mb={{ base: '20px', md: 'auto' }}>
						<FormControl>
							<FormLabel display='flex' ms='4px' fontSize='sm' fontWeight='500' color={textColor} mb='8px'>
								Email<Text color={brandStars}>*</Text>
							</FormLabel>
							<Input
								style={(formik.touched.username && formik.errors.username) ? { border: '1.5px solid red' } : { marginBottom: '24px'}}
								{...formik.getFieldProps('username')} 
								variant='auth'
								fontSize='sm'
								ms={{ base: '0px', md: '0px' }}
								type='email'
								placeholder='mail@simmmple.com'
								fontWeight='500'
								size='lg'
							/>
							{formik.touched.username && formik.errors.username ? (
								<div className="error-message" style={{ marginBottom: '12px' }}>{formik.errors.username}</div>
							) : null}
							<FormLabel ms='4px' fontSize='sm' fontWeight='500' color={textColor} display='flex'>
								Password<Text color={brandStars}>*</Text>
							</FormLabel>
							<InputGroup size='md'>
								<Input
									style={(formik.touched.password && formik.errors.password) ? { border: '1.5px solid red' } : { marginBottom: '24px' }}
									{...formik.getFieldProps('password')}
									fontSize='sm'
									placeholder='Min. 8 characters'
									size='lg'
									type={show ? 'text' : 'password'}
									variant='auth'
								/>
								<InputRightElement display='flex' alignItems='center' mt='4px'>
									<Icon
										color={textColorSecondary}
										_hover={{ cursor: 'pointer' }}
										as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
										onClick={handleClick}
									/>
								</InputRightElement>
							</InputGroup>
							{formik.touched.password && formik.errors.password ? (
									<div className="error-message" style={{ marginBottom: '12px' }}>{formik.errors.password}</div>
								) : null}
							<Flex justifyContent='space-between' align='center' mb='24px'>
								<FormControl display='flex' alignItems='center'>
									<Checkbox id='remember-login' colorScheme='brandScheme' me='10px' />
									<FormLabel
										htmlFor='remember-login'
										mb='0'
										fontWeight='normal'
										color={textColor}
										fontSize='sm'>
										Keep me logged in
									</FormLabel>
								</FormControl>
							</Flex>
							<Button type='submit' isLoading={loading} fontSize='sm' variant='brand' fontWeight='500' w='100%' h='50' mb='24px' >
								Sign In
							</Button>
						</FormControl>
					</Flex>
				</Flex>
			</form>
		</DefaultAuth>
	);
}

export default SignIn;
