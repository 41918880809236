import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, useColorModeValue, FormLabel, Select, Icon, toast, useToast } from '@chakra-ui/react';
import { AssistanceFormPropTypes } from 'types/propTypes';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import http from 'services';
import axios from 'axios';
import { connect } from 'react-redux';
import { getFileNameFromUrl, isAudioFormatSupported, isVideo } from 'helpers/functions';
import { addAssistance, addAssistanceSupportingMedia, deleteAssistanceSupportingMedia, getAssistancesList, updateAssistance } from 'store/Actions/AssistanceActions';
import AssistanceValidation from 'validations/assistanceValidation';
import InputField from 'components/fields/InputField';
import MediaModal from 'components/MediaModal';
import { MdAddCircle } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

function AssistanceForm(props: AssistanceFormPropTypes) {
	const { selectedBeacon, museum, deleteAssistanceSupportingMedia, addAssistanceSupportingMedia, lookups, beacons, assistances, setNotification, loading, setLoading, modal, onClose, assistance, addAssistance, updateAssistance, setAssistance, getAssistancesList } = props;

	const toast = useToast();
	const [mediaUrl, setMediaUrl] = useState(null);
	const [mediaUrlError, setMediaUrlError] = useState(false);
	const [uploadPercentage, setUploadPercentage] = useState(null);
	const [typeError, setTypeError] = useState(false);
	const [mediaModal, setMediaModal] = useState(false);
	const [media, setMedia] = useState(null);
	const [supportingMediasArray, setSupportingMediasArray] = useState([]);
	const [addSupportingMediasArray, setAddSupportingMediasArray] = useState([]);
	const [SMUploadPercentage, setSMUploadPercentage] = useState(null);
	const [deletedSupportingMedia, setDeletedSupportingMedia] = useState(null);
	const [audioFiles, setAudioFiles] = useState([]);
	const [videoFiles, setVideoFiles] = useState([]);
	const [allMediaFiles, setAllMediaFiles] = useState([]);
	const [deleteLoading, setDeleteLoading] = useState(false);
	// @ts-ignore
	const { id } = useParams();

	useEffect(() => {
		http.getMediaLibraries().then(res => {
			const allFiles = res.data.data;
			const audioFiles = allFiles.filter((file: any) => file.name.endsWith(".mp3") || file.name.endsWith(".aif"));
			const videoFiles = allFiles.filter((file: any) => file.name.endsWith(".mp4"));
			setAudioFiles(audioFiles);
			setVideoFiles(videoFiles);
			setAllMediaFiles(allFiles);
		});
	}, []);

	const formik = useFormik({
		initialValues: {
			type: assistance?.type || "",
			mediaId: assistance?.mediaId || ""
		},
		validationSchema: AssistanceValidation,
		enableReinitialize: true,
		onSubmit: async (values, { resetForm }) => {
			setLoading(true);
			if (assistance === undefined || Object.keys(assistance).length === 0) {
				http.createAssistance({...values, beaconId: id}).then(async res => {
					toast({
						title: addSupportingMediasArray.length === 0 ? 'Assistance has been saveed' : 'Assistance has been saveed, now saving Supporting Media',
						position: 'top',
						status: 'success',
						isClosable: true
					});
					if(addSupportingMediasArray.length > 0) {
						for (const supportingMediaa of addSupportingMediasArray) {
							const finalData = {
									name: supportingMediaa.name,
									mediaId: supportingMediaa.mediaId,
									assistanceId: res.data.data.id
							};
			
								// Await the API call for each supportingMediaa
								await http.createSupportingMedia(finalData);
						}
				
						// This code will execute after all API calls are completed
						toast({
								title: 'Supporting Medias added successfully',
								position: 'top',
								status: 'success',
								isClosable: true
						});
					}
					setAddSupportingMediasArray([]);
					setSupportingMediasArray([]);
					getAssistancesList(id)
					setLoading(false);
					onClose();
					resetForm();
					setTimeout(() => {
						setNotification({ isVisible: false, heading: '', text: '' })
					}, 10000);
				})
			} else {
				const data = {...values, beaconId: id}
				updateAssistance(assistance.id, data).then(async () => {
					toast({
						title: 'Assistance updated successfully',
						position: 'top',
						status: 'success',
						isClosable: true
					});
					if(supportingMediasArray.length > 0) {
						for (const supportingMediaa of supportingMediasArray) {
							const finalData = {
									name: supportingMediaa.name,
									mediaId: supportingMediaa.mediaId,
									assistanceId: assistance.id
							};
			
								// Await the API call for each supportingMediaa
								await http.createSupportingMedia(finalData);
						}
				
						// This code will execute after all API calls are completed
						toast({
								title: 'Supporting Medias updated successfully',
								position: 'top',
								status: 'success',
								isClosable: true
						});
					}
					setAddSupportingMediasArray([]);
					setSupportingMediasArray([]);
					getAssistancesList(id)
					setLoading(false);
					onClose();
					resetForm();
					setTimeout(() => {
						setNotification({ isVisible: false, heading: '', text: '' })
					}, 10000);
				});
			}
		},
	});

  const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
	const bgHover = useColorModeValue({ bg: 'secondaryGray.400' }, { bg: 'whiteAlpha.50' });
  const bgFocus = useColorModeValue({ bg: 'secondaryGray.400' }, { bg: 'whiteAlpha.100' });
  const iconColor = useColorModeValue('brand.500', 'white');

  const setAddSMHandler = (e: any, id: any) => {
		const tempAddSMArray = [...addSupportingMediasArray];
    tempAddSMArray.forEach((item: any) => {
      if(item.uuid === id) {
        item.mediaId = e.target.value;
				item.name = allMediaFiles.filter(item => parseInt(item.id) === parseInt(e.target.value))[0].name
      }
    });
    setAddSupportingMediasArray(tempAddSMArray);
  }

	const setUpdateSMHandler = (e: any, id: any) => {
		const tempAddSMArray = [...supportingMediasArray];
    tempAddSMArray.forEach((item: any) => {
      if(item.uuid === id) {
        item.mediaId = e.target.value;
				item.name = allMediaFiles.filter(item => parseInt(item.id) === parseInt(e.target.value))[0].name
      }
    });
    setSupportingMediasArray(tempAddSMArray);
  }

	const deleteSupportingMediaHandler = (supMedia: any, e: any) => {
    try {
      setDeletedSupportingMedia(supMedia);
      setDeleteLoading(true);
      deleteAssistanceSupportingMedia(supMedia.assistanceId, supMedia.id).then(() => {
        setAssistance({ ...assistance, supportingMedias: assistance.supportingMedias.filter((sm : any) => sm.id !== supMedia.id)})
      });
    } catch(err) {
      setLoading(false);
    }
  }

	return (
		<>
			<Modal onClose={onClose} size='4xl' isOpen={modal} closeOnOverlayClick={false}>
				<ModalOverlay />
				<ModalContent>
					<form onSubmit={formik.handleSubmit}>
						<ModalHeader>{(assistance === undefined || Object.keys(assistance).length === 0) ? "Save " : "Update "} Assistance</ModalHeader>
						<ModalCloseButton onClick={() => formik.resetForm()}/>
						<ModalBody>
							<div className="selectedAssistanceBeacon">
								<strong>Beacon ID: </strong> <span>( {selectedBeacon?.uniqueId} )</span>
							</div>
							<SimpleGrid columns={{ sm: 1, md: 2 }} spacing={{ base: '20px', xl: '20px' }}>
								<div>
									<label htmlFor='sound' style={{ marginInlineEnd: '0.75rem', display: 'flex', textAlign: 'start', fontSize: '0.875rem', fontWeight: '700', marginInlineStart: '10px', marginBottom: '0.5rem' }}>Media Type</label>
									<Select
										placeholder='Select Media Type'
										style={(formik.touched.type && formik.errors.type) && { border: '1.5px solid red' }} mb='0px' me='30px'
										{...formik.getFieldProps('type')}
										id='type'
									>
										{museum.behaviourSupport && (
											<option value="BEHAVIOUR">BEHAVIOUR</option>
										)}
										{museum.assistiveListening && (
											<>
												<option value="ASSISTIVE">ASSISTIVE</option>
												<option value="ASSISTIVE_PORTRAIT">ASSISTIVE_PORTRAIT</option>
											</>
										)}
										{museum.audioDescriptor && (
											<option value="AUDIO">AUDIO</option>
										)}
									</Select>
									{formik.touched.type && formik.errors.type ? (
										<div className="error-message">{formik.errors.type}</div>
									) : null}
								</div>
								<div>
									<label htmlFor='media' style={{ marginInlineEnd: '0.75rem', display: 'flex', textAlign: 'start', fontSize: '0.875rem', fontWeight: '700', marginInlineStart: '10px', marginBottom: '0.5rem' }}>Select Media</label>
									<Select
										placeholder='Select Media'
										style={(formik.touched.mediaId && formik.errors.mediaId) && { border: '1.5px solid red' }} mb='0px' me='30px'
										{...formik.getFieldProps('mediaId')}
										id='mediaId'
									>
										{formik.values.type === 'AUDIO' ? (
											<>
												{audioFiles.map(audioFile => (
													<option value={audioFile.id}>{audioFile.name}</option>
												))}
											</>
										) : (
											<>
												{videoFiles.map(videoFile => (
													<option value={videoFile.id}>{videoFile.name}</option>
												))}
											</>
										)}
										
									</Select>
									{formik.touched.mediaId && formik.errors.mediaId ? (
										<div className="error-message">{formik.errors.mediaId}</div>
									) : null}
								</div>
							</SimpleGrid>
							<br />
							<SimpleGrid columns={{ sm: 1, md: 1 }} spacing={{ base: '20px', xl: '20px' }}>
                {!(assistance === undefined || Object.keys(assistance).length === 0) && (
                  <div className='previous-file'>
                    <p style={{ paddingTop: '8px', fontWeight: 'bolder' }}>{getFileNameFromUrl(assistance.mediaUrl)}</p>
                    <Button colorScheme='teal' variant='outline' onClick={
                      () => { 
                        if(!isAudioFormatSupported(getFileNameFromUrl(assistance.mediaUrl))) {
                          window.open(assistance.mediaUrl, "_blank")
                        } else {
                          setMedia(assistance.mediaUrl); setMediaModal(true)
                        }
                      } 
                    }>
                      Play Media
                    </Button>
                  </div>
                )}
                {(!(assistance === undefined || Object.keys(assistance).length === 0)) && !isVideo(getFileNameFromUrl(assistance.mediaUrl)) && (
                  <>
                    {!(assistance === undefined || Object.keys(assistance).length === 0) && (
                      <div>
                        <label htmlFor='supportingMedias' className='supportingMediaLabel'>Supporting Medias</label>
                        {assistance.supportingMedias.length === 0 && (
                          <div className='supportingMediaBox' id="supportingMedias">
                            No Supporting Medias
                          </div>
                        )}
                        {assistance.supportingMedias.map((media: any) => (
                          <div className='supportingMediaBox' id="supportingMedias">
                            <p style={{ paddingTop: '8px', fontWeight: '200' }}>{media.name}</p>
                            <div>
                              <Button colorScheme='teal' variant='outline' onClick={
                                () => { 
                                    if(!isAudioFormatSupported(getFileNameFromUrl(media.url))) {
                                      window.open(media.url, "_blank")
                                    } else {
                                      setMedia(media.url); setMediaModal(true)
                                    }
                                  } 
                                }>
                                Play Media
                              </Button>
                              {/* @ts-ignore */}
                              <Button disabled={media.id === deletedSupportingMedia?.id && deleteLoading} ml={2} colorScheme='red' variant='solid' onClick={deleteSupportingMediaHandler.bind(this, media)}>
                                {media.id === deletedSupportingMedia?.id && deleteLoading ? "Deleting..." : "Delete"}
                              </Button>
                            </div>
                          </div>
                        ))}
                        <div>
                        {supportingMediasArray?.map(supMed => (
                          <div className='previous-file' style={{ marginTop: '5px' }}>
														<div>
															<label htmlFor='media' style={{ marginInlineEnd: '0.75rem', display: 'flex', textAlign: 'start', fontSize: '0.875rem', fontWeight: '700', marginInlineStart: '10px', marginBottom: '0.5rem' }}>Select Media</label>
															<Select
																placeholder='Select Media'
																onChange={(e) => setUpdateSMHandler(e, supMed.uuid)}
															>
																{formik.values.type === 'AUDIO' ? (
																	<>
																		{audioFiles.map(audioFile => (
																			<option value={audioFile.id}>{audioFile.name}</option>
																		))}
																	</>
																) : (
																	<>
																		{videoFiles.map(videoFile => (
																			<option value={videoFile.id}>{videoFile.name}</option>
																		))}
																	</>
																)}
															</Select>
														</div>
                            <div>
                              <Button disabled={SMUploadPercentage} colorScheme='red' variant='solid' onClick={() => { setSupportingMediasArray([])} }>
                                Remove
                              </Button>
                              <Button disabled={SMUploadPercentage} ml={2} colorScheme='teal' variant='outline'>
                                {SMUploadPercentage > 0 ? `Uploading... ${SMUploadPercentage}` : 'Upload'} 
                              </Button>
                            </div>
                          </div>
                        ))}
                        <div style={{ float: 'right', marginTop: "0.5rem" }}>
                          <Button
                              alignItems='center'
                              justifyContent='center'
                              bg={bgButton}
                              _hover={bgHover}
                              _focus={bgFocus}
                              _active={bgFocus}
                              w='37px'
                              h='37px'
                              lineHeight='100%'
                              borderRadius='10px'
                              onClick={() => { setSupportingMediasArray([...supportingMediasArray, {uuid: uuidv4(), mediaId: ''}]) }}
														>
                              <Icon as={MdAddCircle} color={iconColor} w='24px' h='24px' />
                          </Button>
                        </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
                {(assistance === undefined || Object.keys(assistance).length === 0) && formik.values.type === "AUDIO" && (
                  <div>
                  <label htmlFor='supportingMedias' className='supportingMediaLabel'>Supporting Medias</label>
                  <div>
                  {addSupportingMediasArray?.map(supMed => (
                    <div className='previous-file' style={{ marginBottom: '5px' }}>
											<div>
												<label htmlFor='media' style={{ marginInlineEnd: '0.75rem', display: 'flex', textAlign: 'start', fontSize: '0.875rem', fontWeight: '700', marginInlineStart: '10px', marginBottom: '0.5rem' }}>Select Media</label>
												<Select
													placeholder='Select Media'
													onChange={(e) => setAddSMHandler(e, supMed.uuid)}
												>
													{formik.values.type === 'AUDIO' ? (
														<>
															{audioFiles.map(audioFile => (
																<option value={audioFile.id}>{audioFile.name}</option>
															))}
														</>
													) : (
														<>
															{videoFiles.map(videoFile => (
																<option value={videoFile.id}>{videoFile.name}</option>
															))}
														</>
													)}
												</Select>
											</div>
                      <div>
                        <Button disabled={SMUploadPercentage} colorScheme='red' variant='solid' onClick={() => { setAddSupportingMediasArray(addSupportingMediasArray.filter(smid => smid.uuid !== supMed.uuid))} }>
                          {supMed.percentage > 0 ? `Uploading ( ${supMed.percentage} )` : "Remove" }
                        </Button>
                      </div>
                    </div>
                  ))}
                  <div style={{ float: 'right', marginTop: "0.5rem" }}>
                    <Button
                        alignItems='center'
                        justifyContent='center'
                        bg={bgButton}
                        _hover={bgHover}
                        _focus={bgFocus}
                        _active={bgFocus}
                        w='37px'
                        h='37px'
                        lineHeight='100%'
                        borderRadius='10px'
                        onClick={() => { setAddSupportingMediasArray([...addSupportingMediasArray, {uuid: uuidv4(), mediaId: ''}]) }}
                      >
                        <Icon as={MdAddCircle} color={iconColor} w='24px' h='24px' />
                    </Button>
                  </div>
                  </div>
                </div>
                )}
              </SimpleGrid>
						</ModalBody>
						<ModalFooter>
							<Button disabled={SMUploadPercentage || uploadPercentage} onClick={() => { setAddSupportingMediasArray([]); setSupportingMediasArray([]); onClose(); formik.resetForm(); }}>Cancel</Button>
							<Button disabled={SMUploadPercentage || uploadPercentage} ml={2} isLoading={loading} loadingText={(assistance === undefined || Object.keys(assistance).length === 0) ? `Saving ${uploadPercentage ? ("( " + uploadPercentage + "% )") : ""}` : `Updating ${uploadPercentage ? ("( " + uploadPercentage + "% )") : ""}`} colorScheme='blue' type='submit'>Save</Button>
						</ModalFooter>
					</form>
				</ModalContent>
			</Modal>
			<MediaModal
				onClose={() => { setMediaModal(false); setMedia(null) }}
				media={media}
				modal={mediaModal}
			/>
		</>
	);
}

const mapDispatchToProps = {
	addAssistance,
	updateAssistance,
	getAssistancesList,
	addAssistanceSupportingMedia,
	deleteAssistanceSupportingMedia
}

export default connect(null, mapDispatchToProps)(AssistanceForm);