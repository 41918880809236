export const exhibitsColumns = [
  {
    Header: "Name",
    accessor: "exhibitName",
  },
  {
    Header: "Actions",
    accessor: "actions",
  },
];
