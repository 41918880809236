import { ADD_BEACON, DELETE_BEACON, EDIT_BEACON, GET_ALL_BEACONS, RESET_BEACON } from 'store/Actions/types'
import reduxActionType from '../../variables/reduxActionType'

const initialState = {
  data: [] as []
}

function BeaconReducer (state = initialState, action: reduxActionType) {
  switch (action.type) {
    case GET_ALL_BEACONS: {
      return {
        ...state,
        data: action.payload
      }
    }
    case ADD_BEACON: {
      return {
        ...state,
        data: [action.payload, ...state.data]
      }
    }
    case EDIT_BEACON: {
      return {
        ...state,
        data: state.data.map(
          (museum: any, i) => museum.id === action.payload.id ? { ...museum, ...action.payload.data }
              : museum
        )
      }
    }
    case DELETE_BEACON: {
      return {
        ...state,
        data: state.data.filter((museum: any) => museum.id !== action.payload)
      }
    }
    case RESET_BEACON: {
      return {
        ...state,
        data: []
      }
    }
    default:
      return state
  }
}

export default BeaconReducer;
