export const beaconsColumns = [
  {
    Header: "Unique ID",
    accessor: "uniqueId",
  },
  {
    Header: "Exhibit Name",
    accessor: "exhibitName",
  },
  {
    Header: "Surrouding Light",
    accessor: "light",
  },
  {
    Header: "Surrouding Sound",
    accessor: "sound",
  },
  {
    Header: "Live Broadcast",
    accessor: "isLiveBroadcost",
  },
  {
    Header: "Actions",
    accessor: "actions",
  },
];
