// Chakra imports
import { Flex, useToast } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import BeaconsTable from './components/BeaconsTable';
import { beaconsColumns } from './variables/beaconColumns';
import { deleteBeacon, getBeaconsList } from 'store/Actions/BeaconActions';
import BeaconForm from '../form/index';
import Notification from 'components/notification/Notification';
import { getLookupsList } from 'store/Actions/LookupActions';
import { clearAssistance, getAssistancesList } from 'store/Actions/AssistanceActions';
import { RootContext } from 'contextApi';
import { getExhibitList } from 'store/Actions/ExhibitActions';

// @ts-ignore
const BeaconList = ({ clearAssistance, getAssistancesList, getBeaconsList, beacons, deleteBeacon, getExhibitList, exhibits, getLookupsList, lookups }) => {

	const [modal, setModal] = useState(false);
	const [beacon, setBeacon] = useState({});
	const [loading, setLoading] = useState(false);
	const [loadingData, setLoadingData] = useState(false);
	const [notification, setNotification] = useState({ isVisible: false, heading: "", text: "" });
	const { museum } = useContext(RootContext);
	const toast = useToast();

	useEffect(() => {
		setLoadingData(true);
		Promise.all([ getBeaconsList(museum), getExhibitList(museum)]).then(res => {
			setLoadingData(false);
		}).finally(() => {
			setLoadingData(false);
		});
		if(lookups && Object.keys(lookups).length === 0) {
			getLookupsList();
		} 
	}, [getBeaconsList, beacons.data.length, lookups, getLookupsList, museum, exhibits?.data.length, getExhibitList, toast]);

	const data:any = [];
	if(exhibits?.data.length > 0 && beacons.data.length > 0) {
		beacons?.data.forEach((item: any) => {
			const tempItem = JSON.parse(JSON.stringify(item));
			tempItem.exhibitName = exhibits?.data?.filter((exhibit: any) => exhibit.id === item.exhibitId)[0]?.exhibitName;
			tempItem.actions = item.id;
			data.push(tempItem);
		})
	}

	const editBeaconHandler = (id: number) => {
		setBeacon(beacons.data.filter((item: any) => item.id === id)[0]);
	}

	const deleteBeaconHandler = (id: number) => {
		try {
			const beacon = beacons.data.filter((item: any) => item.id === id)[0]
			setBeacon(beacon);
			setLoading(true);
			deleteBeacon(id).then((res: any) => {
				getAssistancesList();
				setLoading(false);
				setNotification({
					isVisible: true,
					heading: 'Success',
					text: `Beacon has been deleted successfully`
				});
				setTimeout(() => {
					setNotification({isVisible: false, heading: '', text: ''})
				}, 10000);
			});
		} catch(err) {
			setLoading(false);
		}
	}

	return (
		<Flex direction='column' pt={{ sm: '125px', lg: '75px' }}>
			<Card px='0px'>
				<Notification 
					isVisible={notification.isVisible}
					heading={notification.heading}
					text={notification.text}
				/>
				<BeaconsTable 
					tableData={data} 
					columnsData={beaconsColumns} 
					onOpen={() => setModal(true)}
					setBeacon={editBeaconHandler}
					deleteBeaconHandler={deleteBeaconHandler}
					loading={loading}
					beacon={beacon}
					loadingData={loadingData}
					clearAssistance={clearAssistance}
				/>
			</Card>
			<BeaconForm 
				modal={modal} 
				onClose={() => setModal(false)} beacon={beacon} setBeacon={setBeacon} 
				loading={loading}
				setLoading={setLoading}
				setNotification={setNotification}
				beacons={beacons}
				lookups={lookups}
				exhibits={exhibits}
			/>
		</Flex>
	);
}

const mapStateToProps = (state: any) => ({
  beacons: state.beacons,
	exhibits: state.exhibits,
	lookups: state.lookups,
})

const mapDispatchToProps = {
  getBeaconsList,
	deleteBeacon,
	getExhibitList,
	getLookupsList,
	getAssistancesList,
	clearAssistance
}

export default connect(mapStateToProps, mapDispatchToProps)(BeaconList);